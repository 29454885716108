import { createUseStyles } from "react-jss"

export const useDataGridStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.background.surfaceElevation1,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  textSpan: {
    fontFamily: "source-sans-pro",
    pointerEvents: "all",
  },
  linkText: {
    fontFamily: "source-sans-pro",
    pointerEvents: "all",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.background.accentDefault,
  },
  tableTaskBar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    borderRadius: "8px 8px 0 0",
    border: `1px solid ${theme.palette.background.surfaceElevation3}`,
    alignItems: "center",
    minHeight: 64,
    gap: 40,
  },
  actionBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 20px",
    minHeight: 35,
  },
  searchBar: {
    width: 400,
  },
  flex: {
    display: "flex",
  },
  blankSlate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    border: "none",
    color: theme.palette.grey[700],
  },
  blankSlateIcon: {
    marginBottom: theme.spacing(2),
    width: 80,
    height: 80,
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: "50%",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: `#1E1F2A80`,
  },
  blankSlateText: {
    alignItems: "center",
    textAlign: "center",
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: theme.spacing(1),
  },
  sortButton: {
    padding: 0,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    border: "none",
  },
  agGridContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  rightAligner: {
    marginLeft: "auto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  expandableComponentWrapper: {
    padding: "24px 44px",
    border: `1px solid ${theme.palette.background.surfaceElevation3}`,
  },
  listContainer: {
    display: "flex",
    zIndex: 1000,
    position: "relative",
    justifyContent: "center",
    minWidth: 150,
    padding: "12px 0px",
  },
  actionButton: {
    display: "flex",
    marginTop: 10,
  },
  popOverMenuItem: {
    minWidth: 150,
    textAlign: "center",
  },
  nameWithIconCell: {
    display: "flex",
    alignItems: "center",
    "& :first-child": {
      marginRight: 10,
    },
  },
  timefilterWrapper: {
    border: `1px solid ${theme.palette.background.surfaceElevation3}`,
    alignItems: "center",
  },
}))
