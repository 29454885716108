import { useFeatures } from "flagged"
import { Fragment, Suspense, useEffect } from "react"
import { useDispatch } from "react-redux"
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import ZtnaLaunchPage from "src/components/ZtnaLaunchPage"
import { FEATURE_FLAGS, TENANT_ADMIN_BASE_URL } from "src/constants"
import useFetch from "src/hooks/useFetch"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { setFeatureFlagsFromResponse } from "src/store/ui"
import { FeatureFlagsResponseType, FeatureFlagsType } from "src/store/ui/types"
import {
  AuthRoutesMap,
  END_USER_BASE_ROUTE_PREFIX,
  HomeRoutes,
  PublicRoutesMap,
  Roles,
  RoleValueType,
  ROOT_PATH,
  RouteConfig,
  RouteType,
  ZTNA_ADMIN_BASE_ROUTE,
  ZTNA_APP_BASE_ROUTE,
} from "./config"
import RouteElementWrapper from "./RouteElementWrapper"
import useRoutesStyles from "./Routes.styles"

const constructRouteMap = (configChilds: RouteType[] | undefined): JSX.Element | JSX.Element[] => {
  if (!configChilds || configChilds.length === 0) return <></>

  return configChilds.map((routeItem: RouteType) => {
    const { id, childs, path, isRelativeRoute } = routeItem
    if (childs && childs.length > 0) {
      if (path) {
        return (
          <Route key={`ZtnaRoute-${id}`} path={path.pathId} element={<RouteElementWrapper {...routeItem} />}>
            {constructRouteMap(childs)}
          </Route>
        )
      }

      return <Fragment key={`ZtnaRoute-${id}`}>{constructRouteMap(childs)}</Fragment>
    }

    return (
      <Route
        key={`ZtnaRoute-${id}`}
        path={isRelativeRoute ? `${path?.pathId}/*` : path?.pathId}
        element={<RouteElementWrapper {...routeItem} />}
      />
    )
  })
}

const ZtnaRoutes: React.FC = () => {
  const dispatch = useDispatch()

  const classes = useRoutesStyles()

  const { role = null } = navigator.cookieEnabled
    ? JSON.parse(localStorage.getItem("userData") || JSON.stringify({}))
    : {}

  const { data: featureFlagsData, isLoading: featureFlagsLoading } = useFetch<FeatureFlagsResponseType>({
    apiUrl: role
      ? role === Roles.CUSTOMER_SUPER_ADMIN
        ? `${TENANT_ADMIN_BASE_URL}${FEATURE_FLAGS}`
        : FEATURE_FLAGS
      : null,
    options: { refreshInterval: 60000 },
  })

  useEffect(() => {
    if (!featureFlagsLoading && featureFlagsData) dispatch(setFeatureFlagsFromResponse(featureFlagsData))
  }, [featureFlagsLoading])

  const flags: FeatureFlagsType = useFeatures()

  if (role && flags.isFeatureFlagsLoading) {
    return <ZtnaLaunchPage state="loading" />
  }

  return (
    <Router>
      <Suspense
        fallback={
          <div className={classes.loader}>
            <CircularLoader size="xl" />
          </div>
        }
      >
        <Routes>
          {constructRouteMap(RouteConfig(flags, role))}

          <Route
            path={ROOT_PATH}
            element={
              <Navigate
                to={
                  role
                    ? HomeRoutes[role as RoleValueType]
                    : window.location.pathname.includes(END_USER_BASE_ROUTE_PREFIX)
                    ? AuthRoutesMap.LOGIN.absolutePath
                    : PublicRoutesMap.XIQ_SESSION_EXPIRE.absolutePath
                }
                replace
              />
            }
          />
          <Route path={ZTNA_APP_BASE_ROUTE} element={<Navigate to={HomeRoutes[role as RoleValueType]} replace />} />
          <Route
            path={`${ROOT_PATH}${ZTNA_ADMIN_BASE_ROUTE}`}
            element={
              <Navigate
                to={role ? HomeRoutes[role as RoleValueType] : AuthRoutesMap.ZTNA_ADMIN_LOGIN.absolutePath}
                replace
              />
            }
          />
          <Route path="*" element={<Navigate to={PublicRoutesMap.NOT_FOUND_PAGE.absolutePath} replace />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default ZtnaRoutes
