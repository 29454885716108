import { createUseStyles } from "react-jss"

export const useDiscoveryAppsDrawerStyles = createUseStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paper": {
      width: 460,
      padding: "20px 22px 20px 28px",
      backgroundColor: theme.palette.grey[100],
    },
  },
  loaderErrorWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "75vh",
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    marginBottom: 14,
    color: theme.palette.grey[50],
  },
  divider: {
    marginTop: 30,
    backgroundColor: theme.palette.secondary[400],
  },
  navIconButton: {
    margin: 0,
    padding: 0,
    minWidth: 0,
  },
  appsTitle: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginTop: 27,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.grey[50],
  },
}))
