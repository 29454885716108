import openNew from "src/assets/images/OpenNew.png"

export const O365Icon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
    <g data-name="Group 4333" transform="translate(538.149 176.617)">
      <circle
        data-name="Ellipse 416"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(-538.149 -176.617)"
        fill="#516382"
      />
      <path
        data-name="Path 2562"
        d="M-506.548-139.816c-.218 0-.2-.152-.2-.3V-150.5c1.931-.719 3.852-1.429 5.769-2.148 1.207-.453 2.407-.926 3.619-1.364a1.033 1.033 0 0 1 .541.086c.923.257 1.843.522 2.764.783.6.171 1.2.337 1.8.514a.31.31 0 0 1 .251.358q-.019 7.078 0 14.156c0 .232-.047.307-.285.371-1.61.432-3.209.9-4.82 1.329a1.217 1.217 0 0 1-.629-.128c-.8-.281-1.6-.573-2.4-.869-.672-.25-1.339-.515-2.01-.765-.949-.353-1.9-.7-2.852-1.048-.524-.194-1.044-.4-1.567-.6.369.04.74.07 1.108.122.646.091 1.288.2 1.934.294 1.064.151 2.13.291 3.195.438.887.122 1.773.249 2.66.37.133.018.268.017.392.023v-12.691c-.378.082-.734.152-1.086.236-1.653.4-3.3.8-4.96 1.192-.177.042-.227.115-.225.285.008.606 0 1.212 0 1.818v6.362c0 .143-.005.257-.168.317q-.826.3-1.646.623c-.435.169-.866.349-1.312.53z"
        transform="translate(-26.279 -18.904)"
        fill="#fff"
      />
    </g>
  </svg>
)

export const OnboardingStep1Icon = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#8981E5" />
    <path
      d="M13.0824 21V19.496H15.3544V12.808H13.4824V11.656C13.9838 11.56 14.4211 11.448 14.7944 11.32C15.1678 11.1813 15.5144 11.016 15.8344 10.824H17.2104V19.496H19.2104V21H13.0824Z"
      fill="#1E1F2A"
    />
  </svg>
)

export const OnboardingStep2Icon = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#8981E5" />
    <path
      d="M12.5064 21V19.928C13.4558 19.0107 14.2718 18.1947 14.9544 17.48C15.6478 16.7547 16.1811 16.0987 16.5544 15.512C16.9278 14.9147 17.1144 14.36 17.1144 13.848C17.1144 13.3147 16.9704 12.888 16.6824 12.568C16.3944 12.248 15.9784 12.088 15.4344 12.088C15.0398 12.088 14.6771 12.2 14.3464 12.424C14.0264 12.648 13.7278 12.9147 13.4504 13.224L12.4264 12.2C12.8958 11.6987 13.3811 11.3147 13.8824 11.048C14.3838 10.7707 14.9811 10.632 15.6744 10.632C16.6558 10.632 17.4344 10.9147 18.0104 11.48C18.5971 12.0453 18.8904 12.8027 18.8904 13.752C18.8904 14.3493 18.7198 14.9627 18.3784 15.592C18.0478 16.2107 17.5998 16.8453 17.0344 17.496C16.4798 18.1467 15.8664 18.8293 15.1944 19.544C15.4611 19.5227 15.7491 19.5013 16.0584 19.48C16.3678 19.448 16.6451 19.432 16.8904 19.432H19.3864V21H12.5064Z"
      fill="#1E1F2A"
    />
  </svg>
)

export const OnboardingStep3Icon = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#8981E5" />
    <path
      d="M15.7384 21.192C14.9171 21.192 14.2238 21.0533 13.6584 20.776C13.1038 20.488 12.6451 20.1467 12.2824 19.752L13.1624 18.568C13.4718 18.8773 13.8238 19.144 14.2184 19.368C14.6131 19.5813 15.0664 19.688 15.5784 19.688C16.1331 19.688 16.5811 19.5547 16.9224 19.288C17.2744 19.0107 17.4504 18.632 17.4504 18.152C17.4504 17.8 17.3598 17.496 17.1784 17.24C17.0078 16.9733 16.7038 16.7707 16.2664 16.632C15.8398 16.4933 15.2371 16.424 14.4584 16.424V15.08C15.4611 15.08 16.1544 14.9253 16.5384 14.616C16.9224 14.3067 17.1144 13.9173 17.1144 13.448C17.1144 13.0213 16.9811 12.6907 16.7144 12.456C16.4478 12.2107 16.0798 12.088 15.6104 12.088C15.2158 12.088 14.8531 12.1787 14.5224 12.36C14.1918 12.5307 13.8718 12.76 13.5624 13.048L12.6184 11.912C13.0558 11.528 13.5198 11.2187 14.0104 10.984C14.5118 10.7493 15.0664 10.632 15.6744 10.632C16.6558 10.632 17.4504 10.8667 18.0584 11.336C18.6664 11.7947 18.9704 12.456 18.9704 13.32C18.9704 13.8853 18.8104 14.3653 18.4904 14.76C18.1811 15.144 17.7491 15.4427 17.1944 15.656V15.72C17.7918 15.88 18.2931 16.1733 18.6984 16.6C19.1038 17.0267 19.3064 17.576 19.3064 18.248C19.3064 18.8667 19.1411 19.3947 18.8104 19.832C18.4904 20.2693 18.0584 20.6053 17.5144 20.84C16.9811 21.0747 16.3891 21.192 15.7384 21.192Z"
      fill="#1E1F2A"
    />
  </svg>
)

export const OnboardingStep4Icon = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#8981E5" />
    <path
      d="M14.0104 16.952H16.6824V14.648C16.6824 14.3493 16.6931 13.9973 16.7144 13.592C16.7358 13.1867 16.7518 12.8347 16.7624 12.536H16.6984C16.5704 12.8027 16.4371 13.064 16.2984 13.32C16.1598 13.576 16.0158 13.8427 15.8664 14.12L14.0104 16.952ZM16.6824 21V18.376H12.1864V17.128L16.2184 10.824H18.4104V16.952H19.6904V18.376H18.4104V21H16.6824Z"
      fill="#1E1F2A"
    />
  </svg>
)

export const OpenNewIcon = (): JSX.Element => <img src={openNew} />

export const OthersIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.434" height="3.336" viewBox="0 0 12.434 3.336">
    <g id="Icon_feather-more-horizontal" transform="translate(-5.252 -15.5)">
      <path
        id="Path_122"
        d="M17.836 17.168a.668.668 0 1 1-.668-.668.668.668 0 0 1 .668.668z"
        transform="translate(-5.825)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        id="Path_123"
        d="M28.336 17.168a.668.668 0 1 1-.668-.668.668.668 0 0 1 .668.668z"
        transform="translate(-11.65)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        id="Path_124"
        d="M7.336 17.168a.668.668 0 1 1-.668-.668.668.668 0 0 1 .668.668z"
        transform="translate(.252)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </g>
  </svg>
)
