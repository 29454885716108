import { ENAlert } from "en-react/dist/src/components/Alert"
import { ENIconClose } from "en-react/dist/src/components/Icons/Close"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { setToasterState } from "src/store/ui/uiSlice"
import { useToasterStyle } from "./Toaster.styles"

interface ToasterProps {
  message?: string
  type?: "success" | "danger" | "warning"
  autoHide?: boolean
  isCloseable?: boolean
  handleOk?: () => void
  messageTitle?: string | React.ReactNode
}

const Toaster: React.FC<ToasterProps> = ({ message, type, isCloseable, messageTitle, autoHide }): JSX.Element => {
  const classes = useToasterStyle()
  const dispatch = useDispatch()

  const onClose = () => {
    setActive(false)
    // Closing Toast
    dispatch(
      setToasterState({
        message: undefined,
        type: "success",
        autoHide: true,
        title: undefined,
      }),
    )
  }

  const [active, setActive] = useState(true)

  useEffect(() => {
    toast(
      <ENAlert isActive={active} variant={type}>
        <ENTextPassage>
          {messageTitle && <h6>{messageTitle}</h6>}
          {message && <p>{message}</p>}
        </ENTextPassage>
      </ENAlert>,
      { onClose },
    )
  }, [])

  return message || messageTitle ? (
    <ToastContainer
      position="top-center"
      closeOnClick={false}
      draggable={false}
      autoClose={autoHide ? 3000 : false}
      className={classes.root}
      hideProgressBar={false}
      bodyStyle={{ padding: 0, margin: 0 }}
      closeButton={
        isCloseable && (
          <div style={{ padding: 14 }}>
            <ENIconClose size="lg" onClick={onClose} />
          </div>
        )
      }
      limit={1}
    />
  ) : (
    <></>
  )
}

export default Toaster
