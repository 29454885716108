import clsx from "clsx"
import ZtnaIcon from "src/shared/components/Icons"
import { useBlankSlateStyles } from "./BlankSlate.styles"

export type BlankSlateProps = {
  text?: string | JSX.Element
  height?: number | string
  width?: string
  iconHeight?: string
  iconWidth?: string
  className?: string
  hideBlankSlateIcon?: boolean
  icon?: JSX.Element
}

const BlankSlate: React.FC<BlankSlateProps> = ({
  text,
  height = 450,
  width = "285px",
  iconHeight,
  iconWidth,
  className,
  hideBlankSlateIcon,
  icon,
}) => {
  const classes = useBlankSlateStyles()

  return (
    <div className={clsx(classes.blankSlate, className)} style={{ height }}>
      {!hideBlankSlateIcon && icon ? (
        <div data-testid="blankSlate-icon" className={classes.singleIcon}>
          <>{icon}</>
        </div>
      ) : hideBlankSlateIcon && !icon ? (
        <></>
      ) : (
        <div data-testid="blankSlate-icon" className={classes.blankSlateIcon}>
          <ZtnaIcon name="routerObject" width={iconWidth} height={iconHeight} />
          <ZtnaIcon name="applicationObject" width={iconWidth} height={iconHeight} />
          <ZtnaIcon name="clientObject" width={iconWidth} height={iconHeight} />
        </div>
      )}
      <div className={classes.blankSlateText} style={{ maxWidth: width }}>
        {text && <span>{text}</span>}
      </div>
    </div>
  )
}

export default BlankSlate
