import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useDispatch } from "react-redux"
import ErrorContainer from "src/shared/components/ErrorContainer"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import Modal from "src/shared/components/Modal"
import { updateSignals } from "src/store/ui/uiSlice"
import { upgradeAllConnectors, upgradeAllRelayNodes } from "../UpgradeBanner.service"
import { useInitiateUpgradeModalStyles } from "./InitiateUpgradeModal.styles"
const mapUpgradeType = {
  relayNode: "UZTNA Gateway",
  connector: "Service Connector",
} as const

interface InitiateUpgradeModalProps {
  type: keyof typeof mapUpgradeType
  entityId?: string
  onModalClose: () => void
}

const InitiateUpgradeModal = ({ type, entityId, onModalClose }: InitiateUpgradeModalProps) => {
  const dispatch = useDispatch()
  const classes = useInitiateUpgradeModalStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  const onSubmit = async () => {
    setError("")
    setIsLoading(true)
    try {
      if (type === "connector") {
        await upgradeAllConnectors()
      }
      if (type === "relayNode") {
        await upgradeAllRelayNodes()
      }
      onModalClose()
      dispatch(
        updateSignals(
          type === "connector" ? ["getServiceConnectors", "getConnectorsExpandedViewData"] : ["getRelayNodes"],
        ),
      )
    } catch (error: any) {
      setIsLoading(false)
      setError(error?.message)
    }
  }

  const information =
    type === "connector"
      ? "The upgrade will be carried out in a step-by-step manner, focusing on individual connector instances sequentially. During this upgrade, there will be a brief traffic disruption lasting 2-3 seconds for the applications connected to the respective service connector instance."
      : "The upgrade of all UZTNA Gateways will occur simultaneously. To prevent any disruptions in traffic, ZTA will make sure to provision backup resources before initiating the upgrade. You may still face traffic disruption of about 2-3 seconds."

  const question =
    type === "connector"
      ? "Are you sure you want to initiate upgrade?"
      : "Would you like to proceed and initiate the upgrade?"

  return (
    <Modal
      title={`Upgrade ${mapUpgradeType[type]}${entityId ? "" : "s"}`}
      width={686}
      onOk={onSubmit}
      onOkProps={{ title: "Initiate Upgrade", isLoading }}
      onCancel={onModalClose}
      onCancelProps={{ disabled: isLoading }}
      contentClass={classes.contentClass}
      disableMinHeight
    >
      <div className={classes.infoContainer}>
        <span className={classes.iconWrapper}>
          <ZtnaIcon name="triangleWarningEmpty" />
        </span>

        <ENTextPassage>
          <strong>{information}</strong>
        </ENTextPassage>
      </div>

      <ENTextPassage>{question}</ENTextPassage>

      <ErrorContainer
        message={error}
        open={!!error}
        handleClose={() => setError("")}
        errorClass={classes.errorContainer}
      />
    </Modal>
  )
}

export default InitiateUpgradeModal
