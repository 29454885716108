import { createUseStyles } from "react-jss"

export const useBlankSlateStyles = createUseStyles((theme) => ({
  blankSlate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    border: "none",
    color: theme.palette.grey[700],
  },
  singleIcon: { display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 21 },
  blankSlateIcon: {
    width: 80,
    marginBottom: 21,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blankSlateText: {
    color: theme.palette.grey[800],
    textAlign: "center",
    fontSize: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  sortButton: {
    padding: 0,
  },
}))
