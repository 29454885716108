import { makeStyles, Theme } from "@material-ui/core"
import { UPGRADE_BANNER_HEIGHT } from "src/constants/layout"

interface StyleProps {
  textColor?: string
  backgroundColor: string
  buttonColor?: string
  dividerColor?: string
}

export const useBannerStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    height: UPGRADE_BANNER_HEIGHT,
    borderRadius: 0,
    color: ({ textColor }) => textColor,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    flexWrap: "nowrap",
    "& .MuiSnackbarContent-message": {
      overflow: "hidden",
    },
    "& .MuiSnackbarContent-action": {
      textWrap: "nowrap",
      display: "flex",
      gap: 10,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    height: 30,
    backgroundColor: ({ dividerColor }) => dividerColor || theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
  button: {
    backgroundColor: "transparent",
    "& .MuiButton-label": {
      color: ({ buttonColor }: { buttonColor: StyleProps["buttonColor"] }) => buttonColor,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      border: 0,
      backgroundColor: "transparent",
    },
  },
  marginRight: { marginRight: theme.spacing(1) },
}))
