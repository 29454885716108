import { themeData as theme } from "src/theme"

export const NoInternetConnectionIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60.896" height="60.621" viewBox="0 0 60.896 60.621">
    <g id="Group_3367" transform="translate(-354.504 -1063.972)">
      <path
        id="Path_2309"
        d="M558.715 1256.973a11.976 11.976 0 0 1 12 11.859c.165 6.154-5.419 11.459-11.715 11.382-6.616-.081-11.881-4.965-11.87-11.583a11.53 11.53 0 0 1 11.585-11.658zm4.44 5.3l-4.419 4.546-4.312-4.368-2.093 1.87 4.644 4.594-4.322 4.206 1.688 2.093 4.529-4.437 4.414 4.148 2.106-1.945-4.48-4.342 4.269-4.467z"
        fill="#ccc"
        transform="translate(-155.317 -155.621)"
      />
      <path
        id="Path_2310"
        d="M368.434 1131.43c-.364 1.889-.8 3.737-1.058 5.61-.321 2.359-.526 4.737-.707 7.112-.053.7-.243.949-.96.943-3.449-.03-6.9-.037-10.347 0-.787.009-.9-.3-.846-.978a29.157 29.157 0 0 1 5.312-14.924 1.144 1.144 0 0 1 .96-.419c2.45.787 4.877 1.646 7.309 2.488a2.793 2.793 0 0 1 .337.168z"
        fill="#ccc"
        transform="translate(0 -52.238)"
      />
      <path
        id="Path_2311"
        d="M596.232 1132.139c2.6-.942 5.1-1.873 7.63-2.728.234-.079.748.243.935.514a29.133 29.133 0 0 1 4.743 11.226c.273 1.447.413 2.919.62 4.426a4.633 4.633 0 0 1-.685.126c-3.515.009-7.029-.005-10.544.024-.718.006-1-.165-1.029-.959a60.169 60.169 0 0 0-1.638-12.247 2.454 2.454 0 0 1-.032-.382z"
        fill="#ccc"
        transform="translate(-194.911 -52.755)"
      />
      <path
        id="Path_2312"
        d="M368.5 1241.239c-1.235.425-2.451.837-3.662 1.26-1.279.448-2.543.942-3.837 1.336a1.163 1.163 0 0 1-1.029-.244 28.435 28.435 0 0 1-5.411-15.4 3.748 3.748 0 0 1 .052-.531 6.441 6.441 0 0 1 .758-.091q5.182-.011 10.365-.011c.51 0 .945-.021.964.722a55.1 55.1 0 0 0 1.752 12.533 3.265 3.265 0 0 1 .048.426z"
        fill="#ccc"
        transform="translate(-0.049 -131.907)"
      />
      <path
        id="Path_2313"
        d="M438.651 1159.752c-2.032 0-4.064-.014-6.1.009-.621.007-.862-.2-.83-.845a54.657 54.657 0 0 1 1.662-11.368c.178-.671.442-.825 1.138-.74q5.133.626 10.282 1.115c.666.064.884.276.879.931q-.037 5.03 0 10.06c0 .645-.217.835-.838.827-2.064-.028-4.128-.01-6.193-.01z"
        fill="#ccc"
        transform="translate(-62.263 -66.775)"
      />
      <path
        id="Path_2314"
        d="M525.191 1159.661c-2.031 0-4.062-.024-6.092.014-.648.012-.832-.211-.829-.832q.03-5.074 0-10.15c0-.562.173-.773.762-.832q5.335-.529 10.661-1.148c.489-.056.69.056.817.53a52.938 52.938 0 0 1 1.725 11.738c.023.591-.25.7-.757.692-2.095-.018-4.19-.007-6.285-.007z"
        fill="#ccc"
        transform="translate(-132.048 -66.705)"
      />
      <path
        id="Path_2315"
        d="M438.85 1227.471c2.031 0 4.063.017 6.093-.009.646-.009.909.166.9.875q-.047 4.931.008 9.863c.008.771-.225 1-1.032 1.074-3.393.321-6.778.733-10.159 1.169-.691.089-1.052.044-1.249-.723a55.7 55.7 0 0 1-1.658-11.363c-.031-.7.256-.889.9-.88 2.063.026 4.127.01 6.19.01z"
        fill="#ccc"
        transform="translate(-62.288 -131.826)"
      />
      <path
        id="Path_2316"
        d="M457.093 1317.172a9.328 9.328 0 0 1-4.123-2.036 24 24 0 0 1-6.914-11.03c1.645-.268 3.273-.59 4.916-.785 1.792-.212 3.6-.307 5.4-.449.5-.04.877.034.874.7-.022 4.387-.02 8.775-.032 13.162a2.081 2.081 0 0 1-.121.438z"
        fill="#ccc"
        transform="translate(-73.821 -192.624)"
      />
      <path
        id="Path_2317"
        d="M457.353 1079.17l-11.033-1.241c1.218-4.976 5.954-12.47 11.033-13.139z"
        fill="#ccc"
        transform="translate(-74.034 -0.66)"
      />
      <path
        id="Path_2318"
        d="M529.578 1077.222c-2.549.318-5.1.652-7.647.945a21.91 21.91 0 0 1-2.8.141c-.23 0-.654-.347-.655-.535-.021-4.479 0-8.958.026-13.437 0-.051.065-.1.241-.364a28.54 28.54 0 0 1 2.93 1.438 15.441 15.441 0 0 1 5.058 5.5c1.1 1.916 2.033 3.929 3.039 5.9z"
        fill="#ccc"
        transform="translate(-132.209 0)"
      />
      <path
        id="Path_2319"
        d="M525.332 1227.469c2 0 4 .022 6-.011.715-.012.948.245.949.955 0 2.089.021 2.072-1.918 2.841a13.671 13.671 0 0 0-7.936 7.482 1.045 1.045 0 0 1-.742.531c-.9.05-1.8-.031-2.705-.018-.5.007-.69-.187-.689-.68.011-3.417.02-6.834 0-10.251 0-.7.32-.865.947-.858 2.031.023 4.062.009 6.094.009z"
        fill="#ccc"
        transform="translate(-132.065 -131.823)"
      />
      <path
        id="Path_2320"
        d="M571.638 1079.112c-2.464.792-4.689 1.523-6.932 2.192-.156.047-.536-.324-.665-.576-1.192-2.318-2.278-4.693-3.55-6.965a34.216 34.216 0 0 0-2.648-3.559c5.486 1.635 9.736 4.815 13.795 8.908z"
        fill="#ccc"
        transform="translate(-163.957 -5.025)"
      />
      <path
        id="Path_2321"
        d="M394.1 1079.3a31.234 31.234 0 0 1 13.394-8.725 32.808 32.808 0 0 0-2.426 3.352c-1.263 2.314-2.39 4.7-3.543 7.077-.2.4-.272.784-.868.587-2.081-.688-4.172-1.348-6.257-2.024-.076-.03-.134-.123-.3-.267z"
        fill="#ccc"
        transform="translate(-31.929 -5.321)"
      />
      <path
        id="Path_2322"
        d="M393.959 1315.423c2.035-.673 3.87-1.345 5.743-1.881 1.539-.439 1.316-.722 1.94.987a27.962 27.962 0 0 0 5.285 9.144.8.8 0 0 1 .212.457 28.763 28.763 0 0 1-13.18-8.707z"
        fill="#ccc"
        transform="translate(-31.814 -200.965)"
      />
      <path
        id="Path_2323"
        d="M615.9 1236.093a16.373 16.373 0 0 0-10.96-4.766c-.053-.072-.09-.1-.089-.122.116-2.96-.385-2.362 2.286-2.384 2.935-.024 5.871.009 8.805-.028.765-.01 1.059.2.927.995-.332 2.02-.623 4.048-.969 6.305z"
        fill="#ccc"
        transform="translate(-201.863 -132.892)"
      />
      <path
        id="Path_2324"
        d="M523.672 1314.229a10.265 10.265 0 0 1-5.289 2.992c-.032-.375-.076-.652-.076-.928 0-4.1.027-8.2-.01-12.3-.007-.862.25-1.193 1.1-1.042a6.588 6.588 0 0 0 1.061.084c.556.007.753.3.68.81a13.69 13.69 0 0 0 2.481 10.166 1.071 1.071 0 0 1 .053.218z"
        fill="#ccc"
        transform="translate(-132.07 -192.668)"
      />
    </g>
  </svg>
)

export const NoResultsFoundIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50.012" viewBox="0 0 50 50.012">
    <g id="Group_3378" transform="translate(-615 -385.452)">
      <path
        id="Icon_ionic-ios-search"
        fill="#ccc"
        d="M53.913 50.885L40.007 36.849A19.817 19.817 0 1 0 37 39.9l13.814 13.941a2.14 2.14 0 0 0 3.021.078 2.154 2.154 0 0 0 .078-3.034zm-29.479-10.82A15.648 15.648 0 1 1 35.5 35.482a15.552 15.552 0 0 1-11.066 4.583z"
        transform="translate(610.5 380.959)"
      />
      <g id="Group_3377" transform="translate(625.792 401.868)">
        <path
          id="Path_2356"
          d="M12824.026 16707.225s3.327 2.217 6.021 0"
          fill="none"
          stroke="#ccc"
          strokeLinecap="round"
          strokeWidth="1.5px"
          transform="translate(-12824.026 -16707.225)"
        />
        <path
          id="Path_2358"
          d="M12824.026 16708.211s3.327-2.219 6.021 0"
          fill="none"
          stroke="#ccc"
          strokeLinecap="round"
          strokeWidth="1.5px"
          transform="translate(-12817.873 -16698.635)"
        />
        <path
          id="Path_2357"
          d="M12824.026 16707.225s3.327 2.217 6.021 0"
          fill="none"
          stroke="#ccc"
          strokeLinecap="round"
          strokeWidth="1.5px"
          transform="translate(-12811.851 -16707.225)"
        />
      </g>
    </g>
  </svg>
)

export const NoServerConnectionIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="104.086" height="95.22" viewBox="0 0 104.086 95.22">
    <g id="Group_3369" transform="translate(-146.839 -410.697)">
      <g id="Group_3368" transform="translate(165.375 433.429)">
        <path
          id="Path_2327"
          d="M437.986 520.478h28.607a6.937 6.937 0 0 0 7.11-7.065v-10.4a6.935 6.935 0 0 0-7.209-7.17h-29.757v-.084h-9.948v.084h-31.515a6.927 6.927 0 0 0-7.117 7.157q-.006 5.148 0 10.293a6.936 6.936 0 0 0 7.2 7.178q15.128.006 30.254 0h1.16v2.541h-31.345a6.946 6.946 0 0 0-7.271 7.316v10.19a6.932 6.932 0 0 0 7.15 7.127h31.487v7.872c-1.263.578-2.343 1.084-3.432 1.568a3.811 3.811 0 0 1-1.362.49c-4.664.037-9.328.023-13.995.023-.891 0-1.786-.037-2.675.018a1.6 1.6 0 0 0-.016 3.189 6.606 6.606 0 0 0 .926.016h15.632v2.541h-14.896c-.549 0-1.1-.033-1.646.017a1.6 1.6 0 0 0-.01 3.191 5.753 5.753 0 0 0 .821.014c5.283 0 10.565-.012 15.848.021a2.343 2.343 0 0 1 1.352.5 4.68 4.68 0 0 0 3.1 1.14q5.351-.046 10.7 0a4.808 4.808 0 0 0 2.934-1.049 2.854 2.854 0 0 1 1.522-.58c4.6-.045 9.193-.027 13.789-.027h.617c1.292-.012 2.076-.644 2.049-1.648-.025-.969-.78-1.57-2.016-1.572q-6.638-.009-13.272 0h-1.1v-2.543h14.653c.807 0 1.512-.362 1.607-1.148a2.459 2.459 0 0 0-.566-1.691 2.1 2.1 0 0 0-1.424-.366c-4.767-.025-9.536 0-14.3-.039a2.83 2.83 0 0 1-1.531-.56 4.47 4.47 0 0 0-2.924-1.066c-.428.021-.856 0-1.263 0v-8.306h30.629a6.926 6.926 0 0 0 7.162-7.112q.006-5.2 0-10.4a6.929 6.929 0 0 0-7.154-7.121H436.718v-2.541zm-.531 38.7c.732 0 .944.342.928 1.018-.031 1.267-.039 2.537 0 3.8.025.763-.284 1.039-1.027 1.027-1.85-.027-3.7-.008-5.551-.008-1.887 0-3.771-.016-5.656.008-.737.01-1.055-.251-1.033-1.02.041-1.3.031-2.6 0-3.907-.012-.667.276-.92.922-.92q5.71.015 11.417-.002zm-7.318-3.278v-8.2h2.391v8.2zm36.447-29.653c2.759 0 3.893 1.117 3.895 3.841q.006 5.3 0 10.6c0 2.543-1.181 3.742-3.689 3.742h-71.726c-2.514 0-3.685-1.2-3.687-3.74q-.006-5.351 0-10.7c0-2.586 1.171-3.738 3.79-3.74h35.709q17.855-.01 35.708-.007zm-36.462-3.3v-2.4h3.242v2.4zm.7-5.687h-35.5c-2.827 0-3.942-1.1-3.944-3.9q-.006-5.249 0-10.5c0-2.617 1.158-3.792 3.742-3.792h71.621c2.584 0 3.738 1.177 3.74 3.794v10.6c0 2.656-1.15 3.792-3.841 3.792z"
          fill="#ccc"
          transform="translate(-388.154 -495.76)"
        />
        <path
          id="Path_2328"
          d="M592.135 618.595v-2.4h3.243v2.4z"
          fill="#ccc"
          transform="translate(-550.168 -591.414)"
        />
        <path
          id="Path_2330"
          d="M728.042 534.163a5.811 5.811 0 1 1-5.823-5.826 6.234 6.234 0 0 1 5.823 5.826zm-5.834 2.58a2.6 2.6 0 0 0 .1-5.2 2.6 2.6 0 1 0-.1 5.2z"
          fill="#ccc"
          transform="translate(-648.882 -521.635)"
        />
        <path
          id="Path_2331"
          d="M515.871 567.369c-.649 0-1.3.024-1.948-.005a1.643 1.643 0 0 1-1.692-1.576 1.62 1.62 0 0 1 1.637-1.626c1.366-.04 2.735-.041 4.1 0a1.606 1.606 0 0 1 1.589 1.567 1.639 1.639 0 0 1-1.637 1.63c-.68.041-1.365.009-2.049.01z"
          fill="#ccc"
          transform="translate(-486.704 -550.065)"
        />
        <path
          id="Path_2332"
          d="M515.973 535.363c-.649 0-1.3.016-1.948 0a1.668 1.668 0 0 1-1.795-1.58 1.638 1.638 0 0 1 1.738-1.631c1.3-.028 2.6-.031 3.9 0a1.633 1.633 0 0 1 1.694 1.574 1.658 1.658 0 0 1-1.739 1.635c-.623.021-1.235.002-1.85.002z"
          fill="#ccc"
          transform="translate(-486.702 -524.645)"
        />
        <path
          id="Path_2333"
          d="M423.4 567.354c-.547 0-1.1.035-1.639-.007a1.634 1.634 0 0 1-1.566-1.595 1.6 1.6 0 0 1 1.557-1.6c1.125-.051 2.256-.05 3.382 0a1.6 1.6 0 0 1 .009 3.191c-.574.047-1.158.011-1.743.011z"
          fill="#ccc"
          transform="translate(-413.607 -550.054)"
        />
        <path
          id="Path_2334"
          d="M467.545 564.186c.513 0 1.027-.028 1.537 0a1.648 1.648 0 0 1 1.639 1.629 1.613 1.613 0 0 1-1.587 1.571c-1.125.045-2.256.049-3.381-.005a1.6 1.6 0 0 1-1.536-1.616 1.63 1.63 0 0 1 1.583-1.565c.581-.045 1.164-.012 1.745-.014z"
          fill="#ccc"
          transform="translate(-448.568 -550.099)"
        />
        <path
          id="Path_2335"
          d="M563.413 567.361c-.513 0-1.027.027-1.537-.006a1.646 1.646 0 0 1-1.631-1.637 1.609 1.609 0 0 1 1.594-1.563c1.092-.045 2.188-.044 3.28 0a1.628 1.628 0 0 1 1.631 1.633 1.647 1.647 0 0 1-1.7 1.572c-.542.022-1.09.001-1.637.001z"
          fill="#ccc"
          transform="translate(-524.839 -550.057)"
        />
        <path
          id="Path_2336"
          d="M563.532 532.171c.547 0 1.1-.031 1.639.007a1.634 1.634 0 0 1 1.6 1.561 1.6 1.6 0 0 1-1.524 1.628 30.53 30.53 0 0 1-3.482-.006 1.523 1.523 0 0 1-1.489-1.545 1.593 1.593 0 0 1 1.515-1.632c.576-.05 1.161-.009 1.741-.009z"
          fill="#ccc"
          transform="translate(-524.861 -524.671)"
        />
        <path
          id="Path_2337"
          d="M467.477 535.316a15.467 15.467 0 0 1-1.741-.013 1.591 1.591 0 0 1 .013-3.178 30.946 30.946 0 0 1 3.483 0 1.526 1.526 0 0 1 1.5 1.54 1.594 1.594 0 0 1-1.508 1.638c-.582.055-1.166.014-1.747.013z"
          fill="#ccc"
          transform="translate(-448.583 -524.607)"
        />
        <path
          id="Path_2338"
          d="M423.531 532.168c.513 0 1.027-.026 1.537.006a1.644 1.644 0 0 1 1.636 1.63 1.611 1.611 0 0 1-1.589 1.568c-1.091.047-2.187.044-3.279 0a1.62 1.62 0 0 1-1.635-1.626 1.647 1.647 0 0 1 1.691-1.577c.545-.023 1.092-.001 1.639-.001z"
          fill="#ccc"
          transform="translate(-413.608 -524.67)"
        />
        <path
          id="Path_2339"
          d="M634.674 551.427c-1.588 0-2.384-.524-2.406-1.584q-.034-1.641 2.452-1.639c1.592 0 2.386.524 2.406 1.583.021 1.096-.791 1.64-2.452 1.64z"
          fill="#ccc"
          transform="translate(-582.044 -537.414)"
        />
        <path
          id="Path_2340"
          d="M678.386 548.205c1.184.016 1.957.674 1.933 1.644-.025 1-.819 1.594-2.1 1.578-1.181-.014-1.955-.673-1.932-1.646.022-1 .813-1.593 2.099-1.576z"
          fill="#ccc"
          transform="translate(-617.005 -537.415)"
        />
        <path
          id="Path_2341"
          d="M728.042 664.087a5.811 5.811 0 1 1-5.818-5.818 6.234 6.234 0 0 1 5.818 5.818zm-5.769-2.6a2.359 2.359 0 0 0-2.645 2.567 2.407 2.407 0 0 0 2.613 2.628 2.376 2.376 0 0 0 2.583-2.544 2.346 2.346 0 0 0-2.551-2.648z"
          fill="#ccc"
          transform="translate(-648.883 -624.835)"
        />
        <path
          id="Path_2342"
          d="M515.845 667.423c-.684 0-1.369.038-2.05-.009a1.621 1.621 0 0 1-1.563-1.595 1.6 1.6 0 0 1 1.561-1.593c1.4-.049 2.8-.048 4.2 0a1.6 1.6 0 0 1 1.562 1.592 1.642 1.642 0 0 1-1.562 1.6c-.373.034-.751.01-1.127.011h-1.025z"
          fill="#ccc"
          transform="translate(-486.705 -629.536)"
        />
        <path
          id="Path_2343"
          d="M515.9 699.445c-.684 0-1.369.031-2.05-.008a1.636 1.636 0 0 1-1.618-1.647 1.609 1.609 0 0 1 1.608-1.55q2.049-.063 4.1 0a1.617 1.617 0 0 1 1.618 1.643 1.646 1.646 0 0 1-1.609 1.557c-.34.027-.683.008-1.025.009H515.9z"
          fill="#ccc"
          transform="translate(-486.703 -654.968)"
        />
        <path
          id="Path_2344"
          d="M423.53 667.42c-.547 0-1.094.022-1.64 0a1.651 1.651 0 0 1-1.692-1.577 1.621 1.621 0 0 1 1.634-1.628c1.092-.04 2.188-.043 3.28 0a1.614 1.614 0 0 1 1.59 1.568 1.642 1.642 0 0 1-1.635 1.632c-.51.031-1.024.005-1.537.005z"
          fill="#ccc"
          transform="translate(-413.606 -629.53)"
        />
        <path
          id="Path_2345"
          d="M467.537 664.25c.547 0 1.1-.037 1.639.009a1.632 1.632 0 0 1 1.544 1.614 1.612 1.612 0 0 1-1.58 1.577c-1.125.047-2.256.049-3.381 0a1.6 1.6 0 0 1-1.543-1.609 1.629 1.629 0 0 1 1.579-1.581c.578-.044 1.162-.01 1.742-.01z"
          fill="#ccc"
          transform="translate(-448.568 -629.574)"
        />
        <path
          id="Path_2346"
          d="M563.418 667.42c-.513 0-1.027.027-1.537-.005a1.647 1.647 0 0 1-1.635-1.634 1.608 1.608 0 0 1 1.59-1.566c1.091-.046 2.187-.044 3.28 0a1.628 1.628 0 0 1 1.635 1.63 1.647 1.647 0 0 1-1.692 1.576c-.547.021-1.094-.001-1.641-.001z"
          fill="#ccc"
          transform="translate(-524.84 -629.529)"
        />
        <path
          id="Path_2347"
          d="M467.473 699.411a15.4 15.4 0 0 1-1.741-.012 1.592 1.592 0 0 1 .012-3.18 31.154 31.154 0 0 1 3.483 0 1.527 1.527 0 0 1 1.5 1.54 1.594 1.594 0 0 1-1.508 1.638c-.581.055-1.165.014-1.746.014z"
          fill="#ccc"
          transform="translate(-448.58 -654.94)"
        />
        <path
          id="Path_2348"
          d="M563.553 696.27c.546 0 1.1-.035 1.639.008a1.627 1.627 0 0 1 1.561 1.6 1.606 1.606 0 0 1-1.561 1.594c-1.125.05-2.256.051-3.381 0a1.6 1.6 0 0 1-1.561-1.592 1.628 1.628 0 0 1 1.561-1.6c.577-.046 1.161-.01 1.742-.01z"
          fill="#ccc"
          transform="translate(-524.843 -655.006)"
        />
        <path
          id="Path_2349"
          d="M423.472 699.413c-.581 0-1.165.042-1.741-.01a1.59 1.59 0 0 1-1.507-1.638 1.528 1.528 0 0 1 1.5-1.539 30.876 30.876 0 0 1 3.483 0 1.592 1.592 0 0 1 .009 3.178 15.643 15.643 0 0 1-1.744.009z"
          fill="#ccc"
          transform="translate(-413.623 -654.942)"
        />
        <path
          id="Path_2350"
          d="M634.685 683.506c-1.6 0-2.386-.513-2.413-1.575q-.042-1.644 2.445-1.647c1.593 0 2.385.516 2.413 1.576.028 1.096-.789 1.647-2.445 1.646z"
          fill="#ccc"
          transform="translate(-582.047 -642.32)"
        />
        <path
          id="Path_2351"
          d="M678.268 683.5c-1.2-.008-1.971-.628-1.979-1.6-.008-1 .787-1.63 2.056-1.624 1.2.005 1.968.63 1.978 1.6.011 1.007-.786 1.636-2.055 1.624z"
          fill="#ccc"
          transform="translate(-617.008 -642.318)"
        />
      </g>
      <path
        id="Path_2309"
        d="M558.46 1256.973a11.713 11.713 0 0 1 11.735 11.6c.161 6.018-5.3 11.207-11.458 11.132-6.471-.079-11.62-4.856-11.61-11.329a11.277 11.277 0 0 1 11.333-11.403zm4.343 5.18l-4.322 4.447-4.218-4.272-2.047 1.829 4.542 4.493-4.227 4.114 1.651 2.047 4.429-4.34 4.317 4.057 2.06-1.9-4.382-4.246 4.175-4.369z"
        fill="#ccc"
        transform="translate(-400.289 -846.276)"
      />
    </g>
  </svg>
)

export const NotAuthorizedIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="58.074" height="58.074" viewBox="0 0 58.074 58.074">
    <g id="Group_703" transform="translate(-169.002 -390.312)">
      <path
        id="Subtraction_14"
        d="M29.035 58.074a29.045 29.045 0 0 1-11.3-55.792 29.045 29.045 0 0 1 22.605 53.51 28.866 28.866 0 0 1-11.305 2.282zm0-53.292a24.255 24.255 0 1 0 24.256 24.254A24.28 24.28 0 0 0 29.035 4.782z"
        fill="#ccc"
        transform="translate(169.002 390.312)"
      />
      <path
        id="Rectangle_202"
        d="M0 0H4.782V52.607H0z"
        fill="#ccc"
        transform="matrix(0.695, -0.719, 0.719, 0.695, 177.455, 402.809)"
      />
    </g>
  </svg>
)

export const NotificationIcon: React.FC<{ color?: string }> = ({ color = "#8e8c92" }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.649" height="18.329" viewBox="0 0 17.649 18.329">
    <g id="Icon_feather-bell" transform="translate(-3.5 .902)">
      <path
        id="Path_105"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M17.541 8.216a5.216 5.216 0 1 0-10.433 0c0 6.086-2.608 7.825-2.608 7.825h15.649s-2.608-1.741-2.608-7.825"
        transform="translate(0 -1.22)"
      />
      <path
        id="Path_106"
        d="M18.413 31.5a1.739 1.739 0 0 1-3.008 0"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        fill="none"
        transform="translate(-4.584 -15.94)"
      />
      <path
        id="Path_107"
        d="M3.008 0C2.7 1.039 2.124 1.679 1.5 1.679S.311 1.039 0 0"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        fill="none"
        transform="rotate(180 6.915 .889)"
      />
    </g>
  </svg>
)

export const NetworkIcon = ({ color = theme.palette.grey.A200 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2125 5.57192C21.1986 5.57192 21.9982 4.77243 21.9982 3.78621C21.9982 2.79999 21.1986 2.00049 20.2125 2.00049C19.2262 2.00049 18.4268 2.79999 18.4268 3.78621C18.4268 4.017 18.4706 4.23759 18.5502 4.44008L15.2978 7.69262C14.6646 7.25609 13.8972 7.00049 13.0702 7.00049C11.7855 7.00049 10.6449 7.61708 9.92813 8.57045L5.52134 5.63259C5.55345 5.49861 5.57045 5.35875 5.57045 5.21492C5.57045 4.2287 4.77095 3.4292 3.78473 3.4292C2.79851 3.4292 1.99902 4.2287 1.99902 5.21492C1.99902 6.20114 2.79851 7.00064 3.78473 7.00064C4.10158 7.00064 4.39916 6.91811 4.65713 6.77338L9.28853 9.86098C9.19281 10.2006 9.14159 10.5588 9.14159 10.9291C9.14159 11.7562 9.3972 12.5235 9.83373 13.1567L4.4379 18.5525C4.2356 18.4729 4.01526 18.4292 3.78473 18.4292C2.79851 18.4292 1.99902 19.2287 1.99902 20.2149C1.99902 21.2011 2.79851 22.0007 3.78473 22.0007C4.77095 22.0007 5.57045 21.2011 5.57045 20.2149C5.57045 19.9848 5.52694 19.7649 5.44769 19.563L10.8441 14.1666C11.2709 14.4606 11.7586 14.6723 12.2846 14.7791V18.5773C11.6532 18.8526 11.2119 19.4822 11.2119 20.2149C11.2119 21.2011 12.0114 22.0007 12.9976 22.0007C13.9839 22.0007 14.7833 21.2011 14.7833 20.2149C14.7833 19.4831 14.3432 18.8541 13.713 18.5783V14.8053C14.5599 14.6659 15.3155 14.2557 15.8879 13.6665L18.5208 15.6413C18.4599 15.8213 18.4268 16.0141 18.4268 16.2146C18.4268 17.2009 19.2262 18.0003 20.2125 18.0003C21.1986 18.0003 21.9982 17.2009 21.9982 16.2146C21.9982 15.2285 21.1986 14.4289 20.2125 14.4289C19.955 14.4289 19.71 14.4835 19.4889 14.5817L16.6822 12.4766C16.886 12.0017 16.9988 11.4786 16.9988 10.9291C16.9988 10.1026 16.7435 9.33575 16.3077 8.703L19.5612 5.44943C19.763 5.52851 19.9827 5.57192 20.2125 5.57192Z"
      fill={color}
    />
  </svg>
)

export const NetworksIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 11.8399C8.56002 11.8399 7.38002 13.0099 7.38002 14.4599C7.38002 15.9099 8.55002 17.0799 10 17.0799C11.45 17.0799 12.62 15.9099 12.62 14.4599C12.62 13.0099 11.45 11.8399 10 11.8399ZM10 15.4799C9.44002 15.4799 8.98 15.0199 8.98 14.4599C8.98 13.8999 9.44002 13.4399 10 13.4399C10.56 13.4399 11.02 13.8999 11.02 14.4599C11.02 15.0199 10.56 15.4799 10 15.4799ZM15.73 8.68994C16.05 8.98994 16.07 9.49995 15.77 9.81995C15.61 9.98995 15.4 10.0699 15.19 10.0699C14.99 10.0699 14.8 9.99994 14.64 9.85994C13.35 8.64994 11.7 7.97993 10 7.97993C8.30002 7.97993 6.66 8.64994 5.36 9.85994C5.04 10.1599 4.53 10.1399 4.23 9.81995C3.93 9.49995 3.95004 8.98994 4.27004 8.68994C5.86004 7.19994 7.90002 6.37994 10 6.37994C12.1 6.37994 14.13 7.19994 15.73 8.68994ZM19.57 6.01994C19.41 6.17994 19.21 6.24994 19 6.24994C18.79 6.24994 18.59 6.16994 18.43 6.01994C16.18 3.76994 13.18 2.52994 10 2.52994C6.82002 2.52994 3.82002 3.76994 1.57002 6.01994C1.26002 6.32994 0.75002 6.32994 0.44002 6.01994C0.13002 5.70994 0.13002 5.19994 0.44002 4.88994C3.00002 2.32994 6.39003 0.929932 10.01 0.929932C13.63 0.929932 17.02 2.33994 19.58 4.88994C19.89 5.19994 19.89 5.70994 19.58 6.01994H19.57Z"
      fill="#F8F8FB"
    />
  </svg>
)
