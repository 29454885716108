import { RETRY_SERVICE_CONNECTORS_UPGRADE, UPGRADE_RELAY_NODES, UPGRADE_SERVICE_CONNECTORS } from "src/constants"
import { postRequest } from "src/services"

export const upgradeAllConnectors = async (): Promise<void> => {
  await postRequest(UPGRADE_SERVICE_CONNECTORS)
}

export const upgradeAllRelayNodes = async (): Promise<void> => {
  await postRequest(UPGRADE_RELAY_NODES)
}

export const retryUpgradeAllConnectors = async (): Promise<void> => {
  await postRequest(RETRY_SERVICE_CONNECTORS_UPGRADE)
}
