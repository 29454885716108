import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useDispatch } from "react-redux"
import ErrorContainer from "src/shared/components/ErrorContainer"
import Modal from "src/shared/components/Modal"
import { closeModal } from "src/store/ui"
import { SWRResponse } from "swr"
import { rolloutUpgrade } from "./RolloutUpgrade.service"
import { useRolloutUpgradeStyles } from "./RolloutUpgrade.styles"
import { VersionsCheckResponseType } from "./useRolloutUpgrade"

interface RolloutUpgradeProps extends VersionsCheckResponseType {
  getRolloutUpgrade: SWRResponse["mutate"]
}

const RolloutUpgrade = ({ connectorVersion, getRolloutUpgrade }: RolloutUpgradeProps) => {
  const dispatch = useDispatch()
  const classes = useRolloutUpgradeStyles({ connectorVersion })

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const onClose = () => dispatch(closeModal("rolloutUpgrade"))

  const onSubmit = async () => {
    try {
      setError("")
      setIsLoading(true)
      await rolloutUpgrade({ connectorVersion })
      getRolloutUpgrade()
      onClose()
    } catch (error: any) {
      setIsLoading(false)
      setError(error?.message)
    }
  }

  return (
    <Modal
      title="Rollout Upgrade"
      onOk={connectorVersion ? onSubmit : onClose}
      onOkProps={{
        title: connectorVersion ? "Rollout" : "Close",
        isLoading,
      }}
      onCancel={onClose}
      onCancelProps={{
        disabled: isLoading,
        visible: !!connectorVersion,
      }}
      contentClass={classes.root}
      disableMinHeight
      width="488px"
    >
      <ENTextPassage>Upgrade available for Service Connector:</ENTextPassage>

      <div className={classes.versionsWrapper}>
        {connectorVersion && (
          <div className={classes.version}>
            <div className={classes.bulletDot} />
            <ENTextPassage>
              <p>
                Service Connector version: <strong>{connectorVersion}</strong>{" "}
              </p>
            </ENTextPassage>
          </div>
        )}
      </div>

      <ENTextPassage>
        <p>Please rollout upgrade to all tenant administrators, enabling them to access the latest version.</p>
      </ENTextPassage>

      <ErrorContainer
        message={error}
        open={!!error}
        handleClose={() => setError("")}
        errorClass={classes.errorContainer}
      />
    </Modal>
  )
}

export default RolloutUpgrade
