import { isMobile } from "react-device-detect"
import { Navigate, useLocation } from "react-router-dom"
import {
  AuthRoutesMap,
  END_USER_BASE_ROUTE_PREFIX,
  HomeRoutes,
  PublicRoutesMap,
  Roles,
  RoleValueType,
  RouteType,
} from "./config"

const RouteElementWrapper = (routeItem: RouteType): JSX.Element => {
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const emailFilter = queries.get("email") || ""
  const requestUri = queries.get("request_uri") || ""

  const { component: RouteComponent, permission, redirectForAuthenticatedUser, title, path } = routeItem
  const { role = null } = navigator.cookieEnabled
    ? JSON.parse(localStorage.getItem("userData") || JSON.stringify({}))
    : {}

  if (navigator.cookieEnabled) {
    const isAuthenticated = role !== null
    const [primaryPermission] = permission
    if (primaryPermission === "public") {
      return isAuthenticated && redirectForAuthenticatedUser ? (
        <Navigate to={HomeRoutes[role as RoleValueType]} replace />
      ) : (
        (RouteComponent && <RouteComponent name={title} />) || <></>
      )
    }

    if (isAuthenticated) {
      if (
        isMobile &&
        (role === Roles.CUSTOMER_SUPER_ADMIN || role === Roles.SUPER_ADMIN) &&
        !window.location.href.includes("/app/service/agentless-tunnel/") &&
        !requestUri.includes("/app/service/agentless-tunnel/") &&
        sessionStorage.getItem("showRecommendation") === null
      ) {
        sessionStorage.setItem(
          "showRecommendation",
          JSON.stringify({
            showRecommendationPage: true,
          }),
        )
        return <Navigate to={PublicRoutesMap.TENANT_ADMIN_RECOMMENDATION.absolutePath} replace />
      }
      const isEndUserPortal = path?.pathId.includes(END_USER_BASE_ROUTE_PREFIX) && role !== Roles.SUPER_ADMIN
      return isEndUserPortal || permission.includes(role) ? (
        (RouteComponent && <RouteComponent name={title} />) || <></>
      ) : (
        <Navigate to={PublicRoutesMap.FORBIDDEN_PAGE.absolutePath} replace />
      )
    }
    return (
      <Navigate
        to={
          !emailFilter
            ? AuthRoutesMap.LOGIN.absolutePath
            : `${AuthRoutesMap.LOGIN.absolutePath}?resend_email=${emailFilter}`
        }
        replace
      />
    )
  }
  return <Navigate to={PublicRoutesMap.COOKIE_ERROR_PAGE.absolutePath} replace />
}

export default RouteElementWrapper
