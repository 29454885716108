import { createUseStyles } from "react-jss"

export const useEncryptionProtocolsStyles = createUseStyles((theme) => ({
  root: {
    textAlign: "center",
    "& .MuiDialog-paperWidthSm": {
      width: 672,
      height: 479,
      padding: "57px 70px",
      borderRadius: 10,
    },
  },
  closeIconContainer: {
    cursor: "pointer",
    position: "absolute",
    top: 19,
    right: 24,
  },
  heading: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.tertiary.main,
    marginBottom: 7,
  },
  text: {
    color: theme.palette.grey[50],
    marginBottom: 36,
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    columnGap: 28,
  },
  button: {
    width: "fit-content",
    position: "absolute",
    bottom: 28,
    right: 25,
  },
  loader: {
    minHeight: 113,
    margin: "98px 0 13px",
  },
  loadingText: {
    width: 356,
    color: theme.palette.grey[50],
    margin: "0 auto",
  },
}))
