import { lazy } from "react"

export const ENIconAccessPoint = lazy(() =>
  import("en-react/dist/src/components/Icons/AccessPoint").then((module) => ({ default: module.ENIconAccessPoint })),
)
export const ENIconAccessPointExtreme = lazy(() =>
  import("en-react/dist/src/components/Icons/AccessPointExtreme").then((module) => ({
    default: module.ENIconAccessPointExtreme,
  })),
)
export const ENIconAccessPointGeneric = lazy(() =>
  import("en-react/dist/src/components/Icons/AccessPointGeneric").then((module) => ({
    default: module.ENIconAccessPointGeneric,
  })),
)
export const ENIconAdd = lazy(() =>
  import("en-react/dist/src/components/Icons/Add").then((module) => ({ default: module.ENIconAdd })),
)
export const ENIconAddSquare = lazy(() =>
  import("en-react/dist/src/components/Icons/AddSquare").then((module) => ({ default: module.ENIconAddSquare })),
)
export const ENIconAddUser = lazy(() =>
  import("en-react/dist/src/components/Icons/AddUser").then((module) => ({ default: module.ENIconAddUser })),
)
export const ENIconAntenna1 = lazy(() =>
  import("en-react/dist/src/components/Icons/Antenna1").then((module) => ({ default: module.ENIconAntenna1 })),
)
export const ENIconApplications = lazy(() =>
  import("en-react/dist/src/components/Icons/Applications").then((module) => ({ default: module.ENIconApplications })),
)
export const ENIconArrowLeft = lazy(() =>
  import("en-react/dist/src/components/Icons/ArrowLeft").then((module) => ({ default: module.ENIconArrowLeft })),
)
export const ENIconArrowRight = lazy(() =>
  import("en-react/dist/src/components/Icons/ArrowRight").then((module) => ({ default: module.ENIconArrowRight })),
)
export const ENIconAttachment = lazy(() =>
  import("en-react/dist/src/components/Icons/Attachment").then((module) => ({ default: module.ENIconAttachment })),
)
export const ENIconBell = lazy(() =>
  import("en-react/dist/src/components/Icons/Bell").then((module) => ({ default: module.ENIconBell })),
)
export const ENIconBlock = lazy(() =>
  import("en-react/dist/src/components/Icons/Block").then((module) => ({ default: module.ENIconBlock })),
)
export const ENIconBook = lazy(() =>
  import("en-react/dist/src/components/Icons/Book").then((module) => ({ default: module.ENIconBook })),
)
export const ENIconBookmark = lazy(() =>
  import("en-react/dist/src/components/Icons/Bookmark").then((module) => ({ default: module.ENIconBookmark })),
)
export const ENIconBridge = lazy(() =>
  import("en-react/dist/src/components/Icons/Bridge").then((module) => ({ default: module.ENIconBridge })),
)
export const ENIconBrowserAlert = lazy(() =>
  import("en-react/dist/src/components/Icons/BrowserAlert").then((module) => ({ default: module.ENIconBrowserAlert })),
)
export const ENIconBrowserChrome = lazy(() =>
  import("en-react/dist/src/components/Icons/BrowserChrome").then((module) => ({
    default: module.ENIconBrowserChrome,
  })),
)
export const ENIconBuilding = lazy(() =>
  import("en-react/dist/src/components/Icons/Building").then((module) => ({ default: module.ENIconBuilding })),
)
export const ENIconBuilding2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Building2").then((module) => ({ default: module.ENIconBuilding2 })),
)
export const ENIconBulb = lazy(() =>
  import("en-react/dist/src/components/Icons/Bulb").then((module) => ({ default: module.ENIconBulb })),
)
export const ENIconCalendar = lazy(() =>
  import("en-react/dist/src/components/Icons/Calendar").then((module) => ({ default: module.ENIconCalendar })),
)
export const ENIconCalendar2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Calendar2").then((module) => ({ default: module.ENIconCalendar2 })),
)
export const ENIconCameraSurveillance = lazy(() =>
  import("en-react/dist/src/components/Icons/CameraSurveillance").then((module) => ({
    default: module.ENIconCameraSurveillance,
  })),
)
export const ENIconCartEmpty = lazy(() =>
  import("en-react/dist/src/components/Icons/CartEmpty").then((module) => ({ default: module.ENIconCartEmpty })),
)
export const ENIconCartFull = lazy(() =>
  import("en-react/dist/src/components/Icons/CartFull").then((module) => ({ default: module.ENIconCartFull })),
)
export const ENIconCautionFilled = lazy(() =>
  import("en-react/dist/src/components/Icons/CautionFilled").then((module) => ({
    default: module.ENIconCautionFilled,
  })),
)
export const ENIconCenter = lazy(() =>
  import("en-react/dist/src/components/Icons/Center").then((module) => ({ default: module.ENIconCenter })),
)
export const ENIconChart = lazy(() =>
  import("en-react/dist/src/components/Icons/Chart").then((module) => ({ default: module.ENIconChart })),
)
export const ENIconChartNotification = lazy(() =>
  import("en-react/dist/src/components/Icons/ChartNotification").then((module) => ({
    default: module.ENIconChartNotification,
  })),
)
export const ENIconCharts = lazy(() =>
  import("en-react/dist/src/components/Icons/Charts").then((module) => ({ default: module.ENIconCharts })),
)
export const ENIconChatHistory = lazy(() =>
  import("en-react/dist/src/components/Icons/ChatHistory").then((module) => ({ default: module.ENIconChatHistory })),
)
export const ENIconChatNew = lazy(() =>
  import("en-react/dist/src/components/Icons/ChatNew").then((module) => ({ default: module.ENIconChatNew })),
)
export const ENIconChevronDown = lazy(() =>
  import("en-react/dist/src/components/Icons/ChevronDown").then((module) => ({ default: module.ENIconChevronDown })),
)
export const ENIconChevronLeft = lazy(() =>
  import("en-react/dist/src/components/Icons/ChevronLeft").then((module) => ({ default: module.ENIconChevronLeft })),
)
export const ENIconChevronRight = lazy(() =>
  import("en-react/dist/src/components/Icons/ChevronRight").then((module) => ({ default: module.ENIconChevronRight })),
)
export const ENIconChevronUp = lazy(() =>
  import("en-react/dist/src/components/Icons/ChevronUp").then((module) => ({ default: module.ENIconChevronUp })),
)
export const ENIconCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/Circle").then((module) => ({ default: module.ENIconCircle })),
)
export const ENIconCircles = lazy(() =>
  import("en-react/dist/src/components/Icons/Circles").then((module) => ({ default: module.ENIconCircles })),
)
export const ENIconClock = lazy(() =>
  import("en-react/dist/src/components/Icons/Clock").then((module) => ({ default: module.ENIconClock })),
)
export const ENIconClose = lazy(() =>
  import("en-react/dist/src/components/Icons/Close").then((module) => ({ default: module.ENIconClose })),
)
export const ENIconCloud = lazy(() =>
  import("en-react/dist/src/components/Icons/Cloud").then((module) => ({ default: module.ENIconCloud })),
)
export const ENIconComputer = lazy(() =>
  import("en-react/dist/src/components/Icons/Computer").then((module) => ({ default: module.ENIconComputer })),
)
export const ENIconComputerMac = lazy(() =>
  import("en-react/dist/src/components/Icons/ComputerMac").then((module) => ({ default: module.ENIconComputerMac })),
)
export const ENIconController = lazy(() =>
  import("en-react/dist/src/components/Icons/Controller").then((module) => ({ default: module.ENIconController })),
)
export const ENIconCookies = lazy(() =>
  import("en-react/dist/src/components/Icons/Cookies").then((module) => ({ default: module.ENIconCookies })),
)
export const ENIconCopy = lazy(() =>
  import("en-react/dist/src/components/Icons/Copy").then((module) => ({ default: module.ENIconCopy })),
)
export const ENIconCustomerSupport = lazy(() =>
  import("en-react/dist/src/components/Icons/CustomerSupport").then((module) => ({
    default: module.ENIconCustomerSupport,
  })),
)
export const ENIconDataCenter = lazy(() =>
  import("en-react/dist/src/components/Icons/DataCenter").then((module) => ({ default: module.ENIconDataCenter })),
)
export const ENIconDelete = lazy(() =>
  import("en-react/dist/src/components/Icons/Delete").then((module) => ({ default: module.ENIconDelete })),
)
export const ENIconDeploy = lazy(() =>
  import("en-react/dist/src/components/Icons/Deploy").then((module) => ({ default: module.ENIconDeploy })),
)
export const ENIconDevices = lazy(() =>
  import("en-react/dist/src/components/Icons/Devices").then((module) => ({ default: module.ENIconDevices })),
)
export const ENIconDocument = lazy(() =>
  import("en-react/dist/src/components/Icons/Document").then((module) => ({ default: module.ENIconDocument })),
)
export const ENIconDocument2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Document2").then((module) => ({ default: module.ENIconDocument2 })),
)
export const ENIconDone = lazy(() =>
  import("en-react/dist/src/components/Icons/Done").then((module) => ({ default: module.ENIconDone })),
)
export const ENIconDoneCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/DoneCircle").then((module) => ({ default: module.ENIconDoneCircle })),
)
export const ENIconDotsHorizontal = lazy(() =>
  import("en-react/dist/src/components/Icons/DotsHorizontal").then((module) => ({
    default: module.ENIconDotsHorizontal,
  })),
)
export const ENIconDotsVertical = lazy(() =>
  import("en-react/dist/src/components/Icons/DotsVertical").then((module) => ({ default: module.ENIconDotsVertical })),
)
export const ENIconDownload = lazy(() =>
  import("en-react/dist/src/components/Icons/Download").then((module) => ({ default: module.ENIconDownload })),
)
export const ENIconDragHandle = lazy(() =>
  import("en-react/dist/src/components/Icons/DragHandle").then((module) => ({ default: module.ENIconDragHandle })),
)
export const ENIconDraw = lazy(() =>
  import("en-react/dist/src/components/Icons/Draw").then((module) => ({ default: module.ENIconDraw })),
)
export const ENIconEc = lazy(() =>
  import("en-react/dist/src/components/Icons/Ec").then((module) => ({ default: module.ENIconEc })),
)
export const ENIconEdit = lazy(() =>
  import("en-react/dist/src/components/Icons/Edit").then((module) => ({ default: module.ENIconEdit })),
)
export const ENIconEii = lazy(() =>
  import("en-react/dist/src/components/Icons/Eii").then((module) => ({ default: module.ENIconEii })),
)
export const ENIconElevator = lazy(() =>
  import("en-react/dist/src/components/Icons/Elevator").then((module) => ({ default: module.ENIconElevator })),
)
export const ENIconEmailClosed = lazy(() =>
  import("en-react/dist/src/components/Icons/EmailClosed").then((module) => ({ default: module.ENIconEmailClosed })),
)
export const ENIconEmailOpen = lazy(() =>
  import("en-react/dist/src/components/Icons/EmailOpen").then((module) => ({ default: module.ENIconEmailOpen })),
)
export const ENIconEmoji = lazy(() =>
  import("en-react/dist/src/components/Icons/Emoji").then((module) => ({ default: module.ENIconEmoji })),
)
export const ENIconEncrypted = lazy(() =>
  import("en-react/dist/src/components/Icons/Encrypted").then((module) => ({ default: module.ENIconEncrypted })),
)
export const ENIconEndpoint = lazy(() =>
  import("en-react/dist/src/components/Icons/Endpoint").then((module) => ({ default: module.ENIconEndpoint })),
)
export const ENIconEnterpriseRouter = lazy(() =>
  import("en-react/dist/src/components/Icons/EnterpriseRouter").then((module) => ({
    default: module.ENIconEnterpriseRouter,
  })),
)
export const ENIconEnterpriseSwitch = lazy(() =>
  import("en-react/dist/src/components/Icons/EnterpriseSwitch").then((module) => ({
    default: module.ENIconEnterpriseSwitch,
  })),
)
export const ENIconFabric = lazy(() =>
  import("en-react/dist/src/components/Icons/Fabric").then((module) => ({ default: module.ENIconFabric })),
)
export const ENIconFeedback = lazy(() =>
  import("en-react/dist/src/components/Icons/Feedback").then((module) => ({ default: module.ENIconFeedback })),
)
export const ENIconFile = lazy(() =>
  import("en-react/dist/src/components/Icons/File").then((module) => ({ default: module.ENIconFile })),
)
export const ENIconFileAlert = lazy(() =>
  import("en-react/dist/src/components/Icons/FileAlert").then((module) => ({ default: module.ENIconFileAlert })),
)
export const ENIconFilter = lazy(() =>
  import("en-react/dist/src/components/Icons/Filter").then((module) => ({ default: module.ENIconFilter })),
)
export const ENIconFilterFilled = lazy(() =>
  import("en-react/dist/src/components/Icons/FilterFilled").then((module) => ({ default: module.ENIconFilterFilled })),
)
export const ENIconFirewall = lazy(() =>
  import("en-react/dist/src/components/Icons/Firewall").then((module) => ({ default: module.ENIconFirewall })),
)
export const ENIconFloorplan = lazy(() =>
  import("en-react/dist/src/components/Icons/Floorplan").then((module) => ({ default: module.ENIconFloorplan })),
)
export const ENIconFolder = lazy(() =>
  import("en-react/dist/src/components/Icons/Folder").then((module) => ({ default: module.ENIconFolder })),
)
export const ENIconFolder2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Folder2").then((module) => ({ default: module.ENIconFolder2 })),
)
export const ENIconFridge = lazy(() =>
  import("en-react/dist/src/components/Icons/Fridge").then((module) => ({ default: module.ENIconFridge })),
)
export const ENIconGlobe = lazy(() =>
  import("en-react/dist/src/components/Icons/Globe").then((module) => ({ default: module.ENIconGlobe })),
)
export const ENIconGroupedNode = lazy(() =>
  import("en-react/dist/src/components/Icons/GroupedNode").then((module) => ({ default: module.ENIconGroupedNode })),
)
export const ENIconHand = lazy(() =>
  import("en-react/dist/src/components/Icons/Hand").then((module) => ({ default: module.ENIconHand })),
)
export const ENIconHandLocked = lazy(() =>
  import("en-react/dist/src/components/Icons/HandLocked").then((module) => ({ default: module.ENIconHandLocked })),
)
export const ENIconHelp = lazy(() =>
  import("en-react/dist/src/components/Icons/Help").then((module) => ({ default: module.ENIconHelp })),
)
export const ENIconHidden = lazy(() =>
  import("en-react/dist/src/components/Icons/Hidden").then((module) => ({ default: module.ENIconHidden })),
)
export const ENIconHistory = lazy(() =>
  import("en-react/dist/src/components/Icons/History").then((module) => ({ default: module.ENIconHistory })),
)
export const ENIconHouse = lazy(() =>
  import("en-react/dist/src/components/Icons/House").then((module) => ({ default: module.ENIconHouse })),
)
export const ENIconImage = lazy(() =>
  import("en-react/dist/src/components/Icons/Image").then((module) => ({ default: module.ENIconImage })),
)
export const ENIconImport = lazy(() =>
  import("en-react/dist/src/components/Icons/Import").then((module) => ({ default: module.ENIconImport })),
)
export const ENIconIncomplete = lazy(() =>
  import("en-react/dist/src/components/Icons/Incomplete").then((module) => ({ default: module.ENIconIncomplete })),
)
export const ENIconIncorrect = lazy(() =>
  import("en-react/dist/src/components/Icons/Incorrect").then((module) => ({ default: module.ENIconIncorrect })),
)
export const ENIconInfo = lazy(() =>
  import("en-react/dist/src/components/Icons/Info").then((module) => ({ default: module.ENIconInfo })),
)
export const ENIconInspect = lazy(() =>
  import("en-react/dist/src/components/Icons/Inspect").then((module) => ({ default: module.ENIconInspect })),
)
export const ENIconInternet = lazy(() =>
  import("en-react/dist/src/components/Icons/Internet").then((module) => ({ default: module.ENIconInternet })),
)
export const ENIconIot = lazy(() =>
  import("en-react/dist/src/components/Icons/Iot").then((module) => ({ default: module.ENIconIot })),
)
export const ENIconIpPhone = lazy(() =>
  import("en-react/dist/src/components/Icons/IpPhone").then((module) => ({ default: module.ENIconIpPhone })),
)
export const ENIconKey = lazy(() =>
  import("en-react/dist/src/components/Icons/Key").then((module) => ({ default: module.ENIconKey })),
)
export const ENIconLaptop = lazy(() =>
  import("en-react/dist/src/components/Icons/Laptop").then((module) => ({ default: module.ENIconLaptop })),
)
export const ENIconLaptop2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Laptop2").then((module) => ({ default: module.ENIconLaptop2 })),
)
export const ENIconLayers = lazy(() =>
  import("en-react/dist/src/components/Icons/Layers").then((module) => ({ default: module.ENIconLayers })),
)
export const ENIconLayout = lazy(() =>
  import("en-react/dist/src/components/Icons/Layout").then((module) => ({ default: module.ENIconLayout })),
)
export const ENIconLayoutForceLink = lazy(() =>
  import("en-react/dist/src/components/Icons/LayoutForceLink").then((module) => ({
    default: module.ENIconLayoutForceLink,
  })),
)
export const ENIconLayoutMasonry = lazy(() =>
  import("en-react/dist/src/components/Icons/LayoutMasonry").then((module) => ({
    default: module.ENIconLayoutMasonry,
  })),
)
export const ENIconLink = lazy(() =>
  import("en-react/dist/src/components/Icons/Link").then((module) => ({ default: module.ENIconLink })),
)
export const ENIconLinkBlocked = lazy(() =>
  import("en-react/dist/src/components/Icons/LinkBlocked").then((module) => ({ default: module.ENIconLinkBlocked })),
)
export const ENIconLinkDownThick = lazy(() =>
  import("en-react/dist/src/components/Icons/LinkDownThick").then((module) => ({
    default: module.ENIconLinkDownThick,
  })),
)
export const ENIconLinkDownThin = lazy(() =>
  import("en-react/dist/src/components/Icons/LinkDownThin").then((module) => ({ default: module.ENIconLinkDownThin })),
)
export const ENIconList = lazy(() =>
  import("en-react/dist/src/components/Icons/List").then((module) => ({ default: module.ENIconList })),
)
export const ENIconLldpSwitch = lazy(() =>
  import("en-react/dist/src/components/Icons/LldpSwitch").then((module) => ({ default: module.ENIconLldpSwitch })),
)
export const ENIconLocationPin = lazy(() =>
  import("en-react/dist/src/components/Icons/LocationPin").then((module) => ({ default: module.ENIconLocationPin })),
)
export const ENIconLocationSecurity = lazy(() =>
  import("en-react/dist/src/components/Icons/LocationSecurity").then((module) => ({
    default: module.ENIconLocationSecurity,
  })),
)
export const ENIconLock = lazy(() =>
  import("en-react/dist/src/components/Icons/Lock").then((module) => ({ default: module.ENIconLock })),
)
export const ENIconMap = lazy(() =>
  import("en-react/dist/src/components/Icons/Map").then((module) => ({ default: module.ENIconMap })),
)
export const ENIconMaximize = lazy(() =>
  import("en-react/dist/src/components/Icons/Maximize").then((module) => ({ default: module.ENIconMaximize })),
)
export const ENIconMenu = lazy(() =>
  import("en-react/dist/src/components/Icons/Menu").then((module) => ({ default: module.ENIconMenu })),
)
export const ENIconMenuTree = lazy(() =>
  import("en-react/dist/src/components/Icons/MenuTree").then((module) => ({ default: module.ENIconMenuTree })),
)
export const ENIconMessage = lazy(() =>
  import("en-react/dist/src/components/Icons/Message").then((module) => ({ default: module.ENIconMessage })),
)
export const ENIconMessage2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Message2").then((module) => ({ default: module.ENIconMessage2 })),
)
export const ENIconMicrowave = lazy(() =>
  import("en-react/dist/src/components/Icons/Microwave").then((module) => ({ default: module.ENIconMicrowave })),
)
export const ENIconMinimize = lazy(() =>
  import("en-react/dist/src/components/Icons/Minimize").then((module) => ({ default: module.ENIconMinimize })),
)
export const ENIconMinus = lazy(() =>
  import("en-react/dist/src/components/Icons/Minus").then((module) => ({ default: module.ENIconMinus })),
)
export const ENIconMobile = lazy(() =>
  import("en-react/dist/src/components/Icons/Mobile").then((module) => ({ default: module.ENIconMobile })),
)
export const ENIconMode = lazy(() =>
  import("en-react/dist/src/components/Icons/Mode").then((module) => ({ default: module.ENIconMode })),
)
export const ENIconMpls = lazy(() =>
  import("en-react/dist/src/components/Icons/Mpls").then((module) => ({ default: module.ENIconMpls })),
)
export const ENIconMplsIpVpn = lazy(() =>
  import("en-react/dist/src/components/Icons/MplsIpVpn").then((module) => ({ default: module.ENIconMplsIpVpn })),
)
export const ENIconNac = lazy(() =>
  import("en-react/dist/src/components/Icons/Nac").then((module) => ({ default: module.ENIconNac })),
)
export const ENIconNearMe = lazy(() =>
  import("en-react/dist/src/components/Icons/NearMe").then((module) => ({ default: module.ENIconNearMe })),
)
export const ENIconNewSession = lazy(() =>
  import("en-react/dist/src/components/Icons/NewSession").then((module) => ({ default: module.ENIconNewSession })),
)
export const ENIconNodes = lazy(() =>
  import("en-react/dist/src/components/Icons/Nodes").then((module) => ({ default: module.ENIconNodes })),
)
export const ENIconOrg1 = lazy(() =>
  import("en-react/dist/src/components/Icons/Org1").then((module) => ({ default: module.ENIconOrg1 })),
)
export const ENIconOrg2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Org2").then((module) => ({ default: module.ENIconOrg2 })),
)
export const ENIconPicker = lazy(() =>
  import("en-react/dist/src/components/Icons/Picker").then((module) => ({ default: module.ENIconPicker })),
)
export const ENIconPin = lazy(() =>
  import("en-react/dist/src/components/Icons/Pin").then((module) => ({ default: module.ENIconPin })),
)
export const ENIconPlug = lazy(() =>
  import("en-react/dist/src/components/Icons/Plug").then((module) => ({ default: module.ENIconPlug })),
)
export const ENIconPower = lazy(() =>
  import("en-react/dist/src/components/Icons/Power").then((module) => ({ default: module.ENIconPower })),
)
export const ENIconPrint = lazy(() =>
  import("en-react/dist/src/components/Icons/Print").then((module) => ({ default: module.ENIconPrint })),
)
export const ENIconProtect = lazy(() =>
  import("en-react/dist/src/components/Icons/Protect").then((module) => ({ default: module.ENIconProtect })),
)
export const ENIconRedo = lazy(() =>
  import("en-react/dist/src/components/Icons/Redo").then((module) => ({ default: module.ENIconRedo })),
)
export const ENIconRefresh = lazy(() =>
  import("en-react/dist/src/components/Icons/Refresh").then((module) => ({ default: module.ENIconRefresh })),
)
export const ENIconRepeater = lazy(() =>
  import("en-react/dist/src/components/Icons/Repeater").then((module) => ({ default: module.ENIconRepeater })),
)
export const ENIconRotateRight = lazy(() =>
  import("en-react/dist/src/components/Icons/RotateRight").then((module) => ({ default: module.ENIconRotateRight })),
)
export const ENIconRouter = lazy(() =>
  import("en-react/dist/src/components/Icons/Router").then((module) => ({ default: module.ENIconRouter })),
)
export const ENIconRouterExtreme = lazy(() =>
  import("en-react/dist/src/components/Icons/RouterExtreme").then((module) => ({
    default: module.ENIconRouterExtreme,
  })),
)
export const ENIconRouterGeneric = lazy(() =>
  import("en-react/dist/src/components/Icons/RouterGeneric").then((module) => ({
    default: module.ENIconRouterGeneric,
  })),
)
export const ENIconRouting = lazy(() =>
  import("en-react/dist/src/components/Icons/Routing").then((module) => ({ default: module.ENIconRouting })),
)
export const ENIconSave = lazy(() =>
  import("en-react/dist/src/components/Icons/Save").then((module) => ({ default: module.ENIconSave })),
)
export const ENIconSdWanAppliance = lazy(() =>
  import("en-react/dist/src/components/Icons/SdWanAppliance").then((module) => ({
    default: module.ENIconSdWanAppliance,
  })),
)
export const ENIconSearch = lazy(() =>
  import("en-react/dist/src/components/Icons/Search").then((module) => ({ default: module.ENIconSearch })),
)
export const ENIconSecureDevices = lazy(() =>
  import("en-react/dist/src/components/Icons/SecureDevices").then((module) => ({
    default: module.ENIconSecureDevices,
  })),
)
export const ENIconSecurity = lazy(() =>
  import("en-react/dist/src/components/Icons/Security").then((module) => ({ default: module.ENIconSecurity })),
)
export const ENIconSend = lazy(() =>
  import("en-react/dist/src/components/Icons/Send").then((module) => ({ default: module.ENIconSend })),
)
export const ENIconServer = lazy(() =>
  import("en-react/dist/src/components/Icons/Server").then((module) => ({ default: module.ENIconServer })),
)
export const ENIconSettings = lazy(() =>
  import("en-react/dist/src/components/Icons/Settings").then((module) => ({ default: module.ENIconSettings })),
)
export const ENIconShare = lazy(() =>
  import("en-react/dist/src/components/Icons/Share").then((module) => ({ default: module.ENIconShare })),
)
export const ENIconSignal = lazy(() =>
  import("en-react/dist/src/components/Icons/Signal").then((module) => ({ default: module.ENIconSignal })),
)
export const ENIconSignalHealthy = lazy(() =>
  import("en-react/dist/src/components/Icons/SignalHealthy").then((module) => ({
    default: module.ENIconSignalHealthy,
  })),
)
export const ENIconSignIn = lazy(() =>
  import("en-react/dist/src/components/Icons/SignIn").then((module) => ({ default: module.ENIconSignIn })),
)
export const ENIconSignOut = lazy(() =>
  import("en-react/dist/src/components/Icons/SignOut").then((module) => ({ default: module.ENIconSignOut })),
)
export const ENIconSocialFacebookCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/SocialFacebookCircle").then((module) => ({
    default: module.ENIconSocialFacebookCircle,
  })),
)
export const ENIconSocialInstagramCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/SocialInstagramCircle").then((module) => ({
    default: module.ENIconSocialInstagramCircle,
  })),
)
export const ENIconSocialLinkedinCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/SocialLinkedinCircle").then((module) => ({
    default: module.ENIconSocialLinkedinCircle,
  })),
)
export const ENIconSocialXCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/SocialXCircle").then((module) => ({
    default: module.ENIconSocialXCircle,
  })),
)
export const ENIconSocialYoutubeCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/SocialYoutubeCircle").then((module) => ({
    default: module.ENIconSocialYoutubeCircle,
  })),
)
export const ENIconStar = lazy(() =>
  import("en-react/dist/src/components/Icons/Star").then((module) => ({ default: module.ENIconStar })),
)
export const ENIconStarFilled = lazy(() =>
  import("en-react/dist/src/components/Icons/StarFilled").then((module) => ({ default: module.ENIconStarFilled })),
)
export const ENIconStop = lazy(() =>
  import("en-react/dist/src/components/Icons/Stop").then((module) => ({ default: module.ENIconStop })),
)
export const ENIconStorage = lazy(() =>
  import("en-react/dist/src/components/Icons/Storage").then((module) => ({ default: module.ENIconStorage })),
)
export const ENIconSwitch = lazy(() =>
  import("en-react/dist/src/components/Icons/Switch").then((module) => ({ default: module.ENIconSwitch })),
)
export const ENIconSwitch2u = lazy(() =>
  import("en-react/dist/src/components/Icons/Switch2u").then((module) => ({ default: module.ENIconSwitch2u })),
)
export const ENIconSwitch4k = lazy(() =>
  import("en-react/dist/src/components/Icons/Switch4k").then((module) => ({ default: module.ENIconSwitch4k })),
)
export const ENIconSwitch5k = lazy(() =>
  import("en-react/dist/src/components/Icons/Switch5k").then((module) => ({ default: module.ENIconSwitch5k })),
)
export const ENIconSwitch7k = lazy(() =>
  import("en-react/dist/src/components/Icons/Switch7k").then((module) => ({ default: module.ENIconSwitch7k })),
)
export const ENIconSwitch8k = lazy(() =>
  import("en-react/dist/src/components/Icons/Switch8k").then((module) => ({ default: module.ENIconSwitch8k })),
)
export const ENIconSwitchAccount = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchAccount").then((module) => ({
    default: module.ENIconSwitchAccount,
  })),
)
export const ENIconSwitchesStack = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchesStack").then((module) => ({
    default: module.ENIconSwitchesStack,
  })),
)
export const ENIconSwitchExtreme = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchExtreme").then((module) => ({
    default: module.ENIconSwitchExtreme,
  })),
)
export const ENIconSwitchFabricEnabled = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchFabricEnabled").then((module) => ({
    default: module.ENIconSwitchFabricEnabled,
  })),
)
export const ENIconSwitchGeneric = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchGeneric").then((module) => ({
    default: module.ENIconSwitchGeneric,
  })),
)
export const ENIconSwitching = lazy(() =>
  import("en-react/dist/src/components/Icons/Switching").then((module) => ({ default: module.ENIconSwitching })),
)
export const ENIconSwitching2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Switching2").then((module) => ({ default: module.ENIconSwitching2 })),
)
export const ENIconSwitchIsw = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchIsw").then((module) => ({ default: module.ENIconSwitchIsw })),
)
export const ENIconSwitchL2 = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchL2").then((module) => ({ default: module.ENIconSwitchL2 })),
)
export const ENIconSwitchL3 = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchL3").then((module) => ({ default: module.ENIconSwitchL3 })),
)
export const ENIconSwitchThirdParty = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchThirdParty").then((module) => ({
    default: module.ENIconSwitchThirdParty,
  })),
)
export const ENIconSwitchUser = lazy(() =>
  import("en-react/dist/src/components/Icons/SwitchUser").then((module) => ({ default: module.ENIconSwitchUser })),
)
export const ENIconTablet = lazy(() =>
  import("en-react/dist/src/components/Icons/Tablet").then((module) => ({ default: module.ENIconTablet })),
)
export const ENIconTag = lazy(() =>
  import("en-react/dist/src/components/Icons/Tag").then((module) => ({ default: module.ENIconTag })),
)
export const ENIconTexture = lazy(() =>
  import("en-react/dist/src/components/Icons/Texture").then((module) => ({ default: module.ENIconTexture })),
)
export const ENIconThumbsDown = lazy(() =>
  import("en-react/dist/src/components/Icons/ThumbsDown").then((module) => ({ default: module.ENIconThumbsDown })),
)
export const ENIconThumbsDownFilled = lazy(() =>
  import("en-react/dist/src/components/Icons/ThumbsDownFilled").then((module) => ({
    default: module.ENIconThumbsDownFilled,
  })),
)
export const ENIconThumbsUp = lazy(() =>
  import("en-react/dist/src/components/Icons/ThumbsUp").then((module) => ({ default: module.ENIconThumbsUp })),
)
export const ENIconThumbsUpFilled = lazy(() =>
  import("en-react/dist/src/components/Icons/ThumbsUpFilled").then((module) => ({
    default: module.ENIconThumbsUpFilled,
  })),
)
export const ENIconTree = lazy(() =>
  import("en-react/dist/src/components/Icons/Tree").then((module) => ({ default: module.ENIconTree })),
)
export const ENIconTroubleshoot = lazy(() =>
  import("en-react/dist/src/components/Icons/Troubleshoot").then((module) => ({ default: module.ENIconTroubleshoot })),
)
export const ENIconTv = lazy(() =>
  import("en-react/dist/src/components/Icons/Tv").then((module) => ({ default: module.ENIconTv })),
)
export const ENIconUndo = lazy(() =>
  import("en-react/dist/src/components/Icons/Undo").then((module) => ({ default: module.ENIconUndo })),
)
export const ENIconUnlink = lazy(() =>
  import("en-react/dist/src/components/Icons/Unlink").then((module) => ({ default: module.ENIconUnlink })),
)
export const ENIconUpload = lazy(() =>
  import("en-react/dist/src/components/Icons/Upload").then((module) => ({ default: module.ENIconUpload })),
)
export const ENIconUrl1 = lazy(() =>
  import("en-react/dist/src/components/Icons/Url1").then((module) => ({ default: module.ENIconUrl1 })),
)
export const ENIconUser = lazy(() =>
  import("en-react/dist/src/components/Icons/User").then((module) => ({ default: module.ENIconUser })),
)
export const ENIconUserGroup = lazy(() =>
  import("en-react/dist/src/components/Icons/UserGroup").then((module) => ({ default: module.ENIconUserGroup })),
)
export const ENIconVideo = lazy(() =>
  import("en-react/dist/src/components/Icons/Video").then((module) => ({ default: module.ENIconVideo })),
)
export const ENIconView = lazy(() =>
  import("en-react/dist/src/components/Icons/View").then((module) => ({ default: module.ENIconView })),
)
export const ENIconVisible = lazy(() =>
  import("en-react/dist/src/components/Icons/Visible").then((module) => ({ default: module.ENIconVisible })),
)
export const ENIconVlan = lazy(() =>
  import("en-react/dist/src/components/Icons/Vlan").then((module) => ({ default: module.ENIconVlan })),
)
export const ENIconVpls = lazy(() =>
  import("en-react/dist/src/components/Icons/Vpls").then((module) => ({ default: module.ENIconVpls })),
)
export const ENIconVrf = lazy(() =>
  import("en-react/dist/src/components/Icons/Vrf").then((module) => ({ default: module.ENIconVrf })),
)
export const ENIconWalk = lazy(() =>
  import("en-react/dist/src/components/Icons/Walk").then((module) => ({ default: module.ENIconWalk })),
)
export const ENIconWall1 = lazy(() =>
  import("en-react/dist/src/components/Icons/Wall1").then((module) => ({ default: module.ENIconWall1 })),
)
export const ENIconWall2 = lazy(() =>
  import("en-react/dist/src/components/Icons/Wall2").then((module) => ({ default: module.ENIconWall2 })),
)
export const ENIconWan = lazy(() =>
  import("en-react/dist/src/components/Icons/Wan").then((module) => ({ default: module.ENIconWan })),
)
export const ENIconWarningCircle = lazy(() =>
  import("en-react/dist/src/components/Icons/WarningCircle").then((module) => ({
    default: module.ENIconWarningCircle,
  })),
)
export const ENIconWarningFilled = lazy(() =>
  import("en-react/dist/src/components/Icons/WarningFilled").then((module) => ({
    default: module.ENIconWarningFilled,
  })),
)
export const ENIconWarningTriangle = lazy(() =>
  import("en-react/dist/src/components/Icons/WarningTriangle").then((module) => ({
    default: module.ENIconWarningTriangle,
  })),
)
export const ENIconWifi = lazy(() =>
  import("en-react/dist/src/components/Icons/Wifi").then((module) => ({ default: module.ENIconWifi })),
)
export const ENIconWifiOff = lazy(() =>
  import("en-react/dist/src/components/Icons/WifiOff").then((module) => ({ default: module.ENIconWifiOff })),
)
export const ENIconXcEdWan = lazy(() =>
  import("en-react/dist/src/components/Icons/XcEdWan").then((module) => ({ default: module.ENIconXcEdWan })),
)
export const ENIconXcUztna = lazy(() =>
  import("en-react/dist/src/components/Icons/XcUztna").then((module) => ({ default: module.ENIconXcUztna })),
)
export const ENIconXiq = lazy(() =>
  import("en-react/dist/src/components/Icons/Xiq").then((module) => ({ default: module.ENIconXiq })),
)
export const ENIconXiqCompanion = lazy(() =>
  import("en-react/dist/src/components/Icons/XiqCompanion").then((module) => ({ default: module.ENIconXiqCompanion })),
)
export const ENIconXiqConnect = lazy(() =>
  import("en-react/dist/src/components/Icons/XiqConnect").then((module) => ({ default: module.ENIconXiqConnect })),
)
export const ENIconXiqCoPilot = lazy(() =>
  import("en-react/dist/src/components/Icons/XiqCoPilot").then((module) => ({ default: module.ENIconXiqCoPilot })),
)
export const ENIconXiqNavigator = lazy(() =>
  import("en-react/dist/src/components/Icons/XiqNavigator").then((module) => ({ default: module.ENIconXiqNavigator })),
)
export const ENIconXiqPilot = lazy(() =>
  import("en-react/dist/src/components/Icons/XiqPilot").then((module) => ({ default: module.ENIconXiqPilot })),
)
export const ENIconXiqSiteEngine = lazy(() =>
  import("en-react/dist/src/components/Icons/XiqSiteEngine").then((module) => ({
    default: module.ENIconXiqSiteEngine,
  })),
)
export const ENIconZoomIn = lazy(() =>
  import("en-react/dist/src/components/Icons/ZoomIn").then((module) => ({ default: module.ENIconZoomIn })),
)
export const ENIconZoomOut = lazy(() =>
  import("en-react/dist/src/components/Icons/ZoomOut").then((module) => ({ default: module.ENIconZoomOut })),
)
