import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import Modal from "src/shared/components/Modal"
import StepperWizard from "src/shared/components/StepperWizard"
import { closeModal } from "src/store/ui"
import IkePolicy from "./Steps/IkePolicy"
import IpsecPolicy from "./Steps/IpsecPolicy"

type IpsecConfigsFormType = {
  ikeVersion: string
  authMethod: string
  ikeEncryptionAlgo: string
  ikeAuthAlgo: string
  ikeDhGroup: string
  ikeLifeTime: number
  ikeAllowedBandwidth: number
  ipsecEncryptionAlgo: string
  ipsecAuthAlgo: string
  ipsecDhGroup: string
  ipsecLifeTime: number
  ipsecAllowedBandwidth: number
}

const IpsecConfigurations = () => {
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0)

  const onCloseModal = () => {
    dispatch(closeModal("ipsecConfigurations"))
  }

  const methods = useForm<IpsecConfigsFormType>({
    defaultValues: {
      ikeVersion: "V2",
      authMethod: "Pre-Shared Keys (PSK)",
      ikeEncryptionAlgo: "aes256-sha256-modp2048",
      ikeAuthAlgo: "aes256-sha256-modp2048",
      ikeDhGroup: "14",
      ikeLifeTime: 600,
      ikeAllowedBandwidth: 86400,
      ipsecEncryptionAlgo: "aes256-sha256-modp2048",
      ipsecAuthAlgo: "aes256-sha256-modp2048",
      ipsecDhGroup: "14",
      ipsecLifeTime: 600,
      ipsecAllowedBandwidth: 86400,
    },
    mode: "onChange",
  })

  return (
    <Modal title="IPSEC Configurations" onOk={() => {}} onCancel={onCloseModal} showFooter={false} width="705px">
      <FormProvider {...methods}>
        <StepperWizard
          header={["IKE Policy", "IPSEC Policy"]}
          renderContent={[
            <IkePolicy setActiveStep={setActiveStep} onCloseModal={onCloseModal} />,
            <IpsecPolicy setActiveStep={setActiveStep} onCloseModal={onCloseModal} />,
          ]}
          activeStep={activeStep}
        />
      </FormProvider>
    </Modal>
  )
}

export default IpsecConfigurations
