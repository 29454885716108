import { VERSIONS_ROLLOUT } from "src/constants"
import { postRequest } from "src/services"
import { VersionsCheckResponseType } from "./useRolloutUpgrade"

export const rolloutUpgrade = async ({ connectorVersion, relayVersion }: VersionsCheckResponseType) => {
  const body = {
    connector_version: connectorVersion,
    relay_version: relayVersion,
  }
  await postRequest(VERSIONS_ROLLOUT, body)
}
