export const QuestionMarkIcon = ({
  width = "12",
  height = "12",
  color = "#007e91",
}: {
  width?: string
  height?: string
  color?: string
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    style={{ marginBottom: "-2px" }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.883 0.166992C2.66302 0.166992 0.0496826 2.78033 0.0496826 6.00031C0.0496826 9.22031 2.66302 11.8337 5.883 11.8337C9.103 11.8337 11.7164 9.22031 11.7164 6.00031C11.7164 2.78033 9.103 0.166992 5.883 0.166992ZM6.46638 10.0837H5.29968V8.917H6.46638V10.0837ZM7.67388 5.56283L7.14888 6.0995C6.85719 6.397 6.64719 6.66531 6.54219 7.08531C6.4955 7.272 6.46638 7.482 6.46638 7.75031H5.29968V7.45869C5.29968 7.19031 5.34635 6.93369 5.42802 6.6945C5.54468 6.35619 5.73718 6.05281 5.98219 5.80783L6.7055 5.07283C6.97388 4.81616 7.10219 4.43116 7.02638 4.02283C6.9505 3.60283 6.62388 3.24699 6.2155 3.13033C5.56802 2.94949 4.96718 3.31699 4.77468 3.87116C4.70468 4.08699 4.52385 4.25033 4.29635 4.25033H4.12135C3.78302 4.25033 3.54968 3.92366 3.64302 3.59699C3.89385 2.73949 4.62302 2.08616 5.52718 1.94616C6.41388 1.80616 7.25969 2.26699 7.78469 2.99616C8.473 3.94699 8.26888 4.96783 7.67388 5.56283Z"
      fill={color}
    />
  </svg>
)
export {}
