import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import React, { Component, ErrorInfo, ReactNode } from "react"
import { useLocation, useParams } from "react-router-dom"
import { RouteWithoutComponentType } from "src/routes/config"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { withRouter } from "./withRouter"

export type RouterHOCTypes = {
  location: ReturnType<typeof useLocation>
  params: ReturnType<typeof useParams>
  navigate: (to: string | number, options?: { replace: boolean }) => void
}

interface Props {
  children: ReactNode | ReactNode[]
  router: RouterHOCTypes
  currentRoute?: RouteWithoutComponentType
}

interface State {
  hasError: boolean
}

class AppContainerErrorBoundaries extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.router.location.pathname !== this.props.router.location.pathname
  }

  goBack = () => {
    const {
      router: { navigate },
    } = this.props
    navigate(-1, { replace: true })
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log("Error:", error)
    console.log("Error Info", errorInfo)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setState({ hasError: false })
    }
  }

  render() {
    const { children, currentRoute } = this.props
    const { hasError } = this.state
    const pageTitle = currentRoute?.tabs?.length
      ? currentRoute.tabs.find((tab) => tab.route === currentRoute.path?.absolutePath)?.tabLabel || "this page"
      : currentRoute?.title || "this page"
    if (hasError === true) {
      return (
        <div className="error-boundary-root">
          <ENTextPassage className="text-align">
            <h1>Oops!</h1>
            <h3>Something went wrong while visiting {pageTitle}</h3>
            <div className="button-container">
              <ZtnaButton title="Go Back" startIcon={<ZtnaIcon name="enChevronLeft" />} onClick={this.goBack} />
            </div>
          </ENTextPassage>
        </div>
      )
    }
    return children
  }
}

export default withRouter(AppContainerErrorBoundaries)
