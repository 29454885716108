import { createUseStyles } from "react-jss"

export const useDiscoveryAppsListStyles = createUseStyles((theme) => ({
  appsList: {
    display: "flex",
    flexDirection: "column",
    marginTop: 17,
  },
  appItem: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    "&:hover $iconsContainer": {
      visibility: "visible",
    },
  },
  appIconAndName: {
    display: "flex",
    alignItems: "center",
    gap: 28,
  },
  appIcon: {
    width: 51,
    height: 51,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    boxShadow:
      "0 3px 2px 0 rgba(97,114,143,0.02),0 7px 57px 0 rgba(97,114,143,0.03),0 13px 10px 0 rgba(97,114,143,0.04),0 22px 18px 0 rgba(97,114,143,0.04),0 42px 33px 0 rgba(97,114,143,0.05),0 100px 80px 0 rgba(97,114,143,0.07)",
  },
  appName: {
    fontWeight: 600,
    color: theme.palette.grey[50],
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  appDivider: {
    width: 331,
    marginTop: 15,
    marginBottom: 20,
    marginLeft: "auto",
    backgroundColor: theme.palette.secondary[400],
  },
  iconsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    position: "absolute",
    right: 8,
    top: 17,
    visibility: "hidden",
    paddingLeft: 8,
    backgroundColor: theme.palette.grey[100],
  },
  infoIconContainer: {
    height: 20,
  },
  navIconContainer: {
    height: 18,
  },
  blankSlate: {
    minHeight: 115,
  },
}))
