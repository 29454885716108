import { makeStyles } from "@material-ui/core"

export const useLicenseValidationBannerStyle = makeStyles(() => ({
  messageContainer: {
    display: "flex",
    alignItems: "center",
    gap: 9,
  },
  message: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))
