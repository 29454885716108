import clsx from "clsx"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import ZtnaIcon from "src/shared/components/Icons"
import themeData from "src/theme"
import { useErrorSlateStyles } from "./ErrorSlate.styles"

const ErrorSlate: React.FC<{
  content?: React.ReactNode
  height?: number | string
  contentWidth?: string
  iconHeight?: string
  iconWidth?: string
  className?: string
  errorSlateIconClass?: string
  errorSlateTextClass?: string
}> = ({
  content,
  height = 450,
  contentWidth = "390px",
  iconHeight,
  iconWidth,
  className,
  errorSlateIconClass,
  errorSlateTextClass,
}) => {
  const classes = useErrorSlateStyles()

  return (
    <div className={clsx(classes.errorSlate, className)} style={{ height }}>
      <div data-testid="error-icon" className={clsx(classes.errorSlateIcon, errorSlateIconClass)}>
        <ZtnaIcon name="enWarningFilled" size="xl" color={themeData.palette.content.dangerOnbg} />
      </div>

      <div className={clsx(classes.errorSlateText, errorSlateTextClass)} style={{ maxWidth: contentWidth }}>
        {content && typeof content !== "string" && <span>{content}</span>}
        {content && typeof content === "string" && content}
        {!content && (
          <ENTextPassage>
            <p>We are unable to fetch data from the servers. Please try refreshing the page or contact support</p>
          </ENTextPassage>
        )}
      </div>
    </div>
  )
}

export default ErrorSlate
