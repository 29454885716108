import { createUseStyles } from "react-jss"

export const useAuthorizationMessageStyle = createUseStyles((theme) => ({
  groupImage: {
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      width: "55%",
      height: 100,
    },
  },
  heading: {
    width: 400,
    fontSize: 26,
    marginTop: 30,
    marginBottom: 5,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    textAlign: "center",
    color: theme.palette.tertiary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      textAlign: "center",
      fontWeight: 700,
      color: theme.palette.grey[50],
    },
  },
  description: {
    display: "flex",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    width: 350,
    marginBottom: 11,
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      width: 330,
      fontSize: 16,
    },
  },
  buttonContainer: {
    marginTop: 25,
  },
}))
