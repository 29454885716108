import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENIconMenu } from "en-react/dist/src/components/Icons/Menu"
import { ENList } from "en-react/dist/src/components/List"
import { ENSideMenu } from "en-react/dist/src/components/SideMenu"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useFeatures } from "flagged"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { END_USER_BASE_ROUTE_PREFIX, Roles, RouteType } from "src/routes/config"
import { getAllowedRoutes } from "src/routes/utils"
import useAppManifest from "src/services/api/swrHooks/useAppManifest"
import { RootState } from "src/store"
import { openModal, setCurrentRoute, setSideNavCurrentRoute, toggleSideNav } from "src/store/ui/uiSlice"
import { COMPANY_NAME } from "src/utils/constants"
import { CollapsibleNavItem } from "./CollapsibleNavItem"
import { NavItem } from "./NavItem"
import { useSideNavStyles } from "./SideNav.styles"

const SideNav: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const flags = useFeatures()
  const queries = new URLSearchParams(search)
  const isRedirectedFromEndUser = queries.get("redirect_url") || ""
  const { role } = JSON.parse(localStorage.getItem("userData") || JSON.stringify({}))
  const isEndUserPortal = pathname.includes(END_USER_BASE_ROUTE_PREFIX) && role !== Roles.SUPER_ADMIN
  const { isOpened, currentRouteId } = useSelector((state: RootState) => state.ui.sideNav)
  const [routes] = role
    ? getAllowedRoutes(
        [isEndUserPortal || isRedirectedFromEndUser === END_USER_BASE_ROUTE_PREFIX ? Roles.END_USER : role],
        flags,
      ).reverse()
    : []
  const allowedRoutes = routes && routes.childs ? routes.childs : []
  const [expandedNavItemRoute, setExpandedNavItemRoute] = useState<RouteType | null>(null)
  const isDevicePostureUnsaved = useSelector((state: RootState) => state.ui.isDevicePostureUnsaved)
  const classes = useSideNavStyles({ isOpened })
  const { data } = useAppManifest()
  const currentTime = new Date()

  const handleSideNavToggle = () => {
    localStorage.setItem("isSideNavOpened", `${!isOpened}`)
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"))
      }, t)
    }
    dispatch(toggleSideNav())
  }

  const handleNavItemClick = (route: RouteType) => {
    const { path } = route
    if (isDevicePostureUnsaved) {
      dispatch(setCurrentRoute(route))
      dispatch(openModal("unsavedPosture"))
    }
    if (path && !isDevicePostureUnsaved) {
      dispatch(setSideNavCurrentRoute(route?.id))
      navigate(path.absolutePath)
    }
  }

  const handleNavItemExpanded = (route: RouteType) => {
    setExpandedNavItemRoute(route)
  }

  const renderNavItem = (route: RouteType, isChild?: boolean) => {
    return (
      <NavItem route={route} selectedRoute={currentRouteId} isChild={isChild} handleNavItemClick={handleNavItemClick} />
    )
  }

  const renderCollapsibleNavItem = (route: RouteType) => {
    return (
      <CollapsibleNavItem
        route={route}
        selectedRoute={currentRouteId}
        handleNavItemClick={handleNavItemClick}
        handleNavItemExpanded={handleNavItemExpanded}
        expandedNavItemRoute={expandedNavItemRoute}
      />
    )
  }

  const renderNavbarItems = (route: RouteType) => {
    const { childs } = route
    return childs?.length ? renderCollapsibleNavItem(route) : renderNavItem(route)
  }

  const sideNavAllowedRoutes = allowedRoutes?.filter((route) => route.showInSideNavigation === true)

  return (
    <>
      <div
        className={clsx(
          classes.sticky,
          classes.navContainer,
          { [classes.drawerPaper]: isOpened },
          { [classes.drawerClose]: !isOpened },
        )}
      >
        <div
          className={clsx(
            { [classes.menuButtonContainerOpened]: isOpened },
            { [classes.menuButtonContainerClosed]: !isOpened },
          )}
        >
          <div>
            <ENButton hideText variant="tertiary" onClick={handleSideNavToggle}>
              <ENIconMenu size="md" slot="before" />
            </ENButton>
          </div>
        </div>
        {isOpened && (
          <div className={classes.navContainer}>
            <ENSideMenu>
              <div className={classes.navMenuContainer}>
                <ENList aria-labelledby="nested-list-subheader">
                  {sideNavAllowedRoutes?.map((route) => {
                    return <Fragment key={`NavBarItem-${route.id}`}>{renderNavbarItems(route)}</Fragment>
                  })}
                </ENList>

                <ENTextPassage className={classes.versionInfo}>
                  <p className={classes.versionInfoText}>
                    © Copyright {currentTime.getFullYear()} {COMPANY_NAME} -
                  </p>
                  <p className={classes.versionInfoText}>All Rights Reserved.</p>
                  <p className={classes.versionInfoText}>
                    Version {data?.version === "APP_VERSION" ? "1.0.0" : data?.version}
                  </p>
                </ENTextPassage>
              </div>
            </ENSideMenu>
          </div>
        )}
      </div>
    </>
  )
}

export default SideNav
