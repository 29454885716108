import { createUseStyles } from "react-jss"

export const useInitiateUpgradeModalStyles = createUseStyles((theme) => ({
  contentClass: {
    minHeight: 154,
    "&.MuiDialogContent-root:first-child": {
      paddingTop: 14,
      paddingLeft: 24,
      paddingRight: 31,
      paddingBottom: 32,
    },
  },
  errorContainer: {
    marginTop: 15,
  },
  infoContainer: {
    display: "flex",
    gap: 15,
    padding: 17,
    border: "1px solid #4D4F63",
    borderRadius: 8,
    margin: "15px 0",
  },
  iconWrapper: {
    marginTop: 5,
  },
}))
