import { ENCheckboxItem } from "en-react/dist/src/components/CheckboxItem"
import { Controller } from "react-hook-form"
import { CheckboxChangeEventType } from "src/utils/utils.types"
import { CheckboxInputType } from "../FormComponents.types"

const CheckboxInput = ({
  id,
  control,
  name,
  label = "",
  className,
  disabled,
  checkedAction,
  uncheckedAction,
}: CheckboxInputType): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => (
        <ENCheckboxItem
          {...props}
          fieldId={id}
          name={name}
          isChecked={props.field.value}
          value={props.field.value}
          onChange={(e) => {
            const isChecked = (e as CheckboxChangeEventType).detail.checked
            props.field.onChange(isChecked)
            if (checkedAction && isChecked) {
              checkedAction()
            } else if (uncheckedAction && !isChecked) {
              uncheckedAction()
            }
          }}
          isDisabled={disabled}
          styleModifier={className}
        >
          {label}
        </ENCheckboxItem>
      )}
    />
  )
}

export default CheckboxInput
