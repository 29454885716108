import { createUseStyles } from "react-jss"

export const useUpgradeBannerStyles = createUseStyles((theme) => ({
  root: {
    top: "2em",
    padding: 0,
    margin: 0,
    width: 800,
    zIndex: theme.zIndex.upgradeRelayBanner,
    "& .Toastify__toast": {
      width: 800,
      border: `1px solid ${theme.palette.border.divider}`,
      borderRadius: 6,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      height: "auto",
      minHeight: "auto",
      backgroundColor: theme.palette.background.surfaceElevation1,
    },
    "& .Toastify__toast-container": {
      width: 800,
      height: "auto",
    },
    "& .Toastify__progress-bar": {
      background: theme.palette.background.accentDefault,
    },
  },
}))

export const useUpgradeMessageStyles = createUseStyles((theme) => ({
  root: {
    gap: 20,
    marginTop: 10,
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
  },
  text: {
    color: theme.palette.neutralDark[13],
  },
}))

export const useUpgradeButtonStyles = createUseStyles((theme) => ({
  button: {
    padding: 14,
    minWidth: 172,
  },
}))
