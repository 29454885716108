import { PROFILE_IMAGE, TENANT_ADMIN_BASE_URL } from "src/constants"
import { Roles, RoleValueType } from "src/routes/config"
import { API_URL, REQUEST_HEADERS } from "src/services"
import useSWR from "swr"

type UseProfileImageResponseType = {
  data?: {
    profileImage: string
  }
  isLoading: boolean
  error: any
  isValidating: boolean
  getProfileImage: () => void
}

const useProfileImage = (role: RoleValueType): UseProfileImageResponseType => {
  const url =
    role === Roles.CUSTOMER_SUPER_ADMIN
      ? `${API_URL}${TENANT_ADMIN_BASE_URL}${PROFILE_IMAGE}`
      : `${API_URL}${PROFILE_IMAGE}`

  const shouldFetch = role !== Roles.END_USER

  const { data, error, mutate, isValidating } = useSWR(shouldFetch ? [url, REQUEST_HEADERS] : null)

  const payload = data?.payload

  return {
    data: payload
      ? {
          profileImage: payload.profileImage,
        }
      : undefined,
    isLoading: !error && !data,
    error,
    getProfileImage: mutate,
    isValidating,
  }
}

export default useProfileImage
